import {Container, Col, Row} from "react-bootstrap";
import {DescriptionInput, ScopeInput, ScopeNameInput} from "../../inputs";

const ScopeForm = (props) => (
    <Container fluid>
        <Row className={"mb-3"}>
            <Col>
                <ScopeNameInput {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <DescriptionInput {...props} />
            </Col>
        </Row>
    </Container>
);

export default ScopeForm;