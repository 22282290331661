const selectScopes = (state) => state.scopes.scopes;

const selectScopesWithContext = (state, contextId) =>
    Object.values(state.scopes)
        .filter((scope) => scope.context_id === contextId);

const selectScopesWithRoot = (state, contextId, root) => {

    let filtered = Object.values(state.scopes)
        .filter((scope) => scope.context_id === contextId);

    if (root && root !== '') {
        filtered = filtered.filter((scope) => {
            let split = scope.scope_label.split('.');
            let rootSplit = root.scope_label.split('.');

            return (scope.scope_label.startsWith(`${root.scope_label}.`) && rootSplit.length === split.length - 1);
        });
    }

    if (!root) {
        filtered = filtered.filter((scope) => !(/\./.test(scope.scope_label)));
    }

    return filtered;
}


const isFulfilled = (state) => state.scopes.status === 'fulfilled';

export {selectScopes, isFulfilled, selectScopesWithContext, selectScopesWithRoot};