import {Button, Modal, Spinner} from "react-bootstrap";
import React from "react";

const FormikModal = ({show, onHide, onCancel, modalHeader, modalBody}) => (formikProps) => (
    <Modal centered show={show} size={"lg"} onHide={onHide}>
        <Modal.Header>
            {modalHeader(formikProps)}
        </Modal.Header>
        <Modal.Body>
            {modalBody(formikProps)}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary"
                    disabled={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}>
                {formikProps.isSubmitting && <Spinner animation="grow" size="sm"/>}
                Submit
            </Button>
            <Button variant="danger"
                    disabled={formikProps.isSubmitting}
                    onClick={onCancel}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
);

export default FormikModal