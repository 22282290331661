import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {IPAM_SERVICE} from "../../api-server";
import ssoFetch from "../../utils/sso-fetch";
import {buildQuery} from "../../utils/url-utils";

const initialState = {
    status: 'idle',
    error: null,
    lastUpdated: +new Date(),
    scopes: {}
};

export const fetchScopesThunk = createAsyncThunk(
    'scopes/fetchScopes',
    ({query, options}) =>
        ssoFetch(
            `${IPAM_SERVICE}/scopes${buildQuery(query)}`,
            {
                method: 'GET',
                ...options
            }
        )
);

export const createScopeThunk = createAsyncThunk(
    'scopes/createScope',
    (scope) =>
        ssoFetch(
            `${IPAM_SERVICE}/scopes`,
            {
                method: 'POST',
                body: JSON.stringify(scope)
            }
        )
);

export const updateScopeThunk = createAsyncThunk(
    'scopes/updateScope',
    (scope) =>
        ssoFetch(
            `${IPAM_SERVICE}/scopes`,
            {
                method: 'PUT',
                body: JSON.stringify(scope)
            }
        )
);

export const deleteScopeThunk = createAsyncThunk(
    'scopes/deleteScope',
    async (args) =>
        ssoFetch(
            `${IPAM_SERVICE}/scopes`,
            {
                method: 'DELETE',
                body: JSON.stringify(args)
            }
        )
);

const scopeSlice = createSlice({
    name: 'scopes',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchScopesThunk.pending]: (state) => {
            state.status = "pending";
        },
        [fetchScopesThunk.fulfilled]: (state, action) => {
            state.scopes = action.payload.reduce(
                (obj, scope) => ({
                    ...obj,
                    [scope['scope_id']]: scope
                }),
                state.scopes // Empty object to init the reduce function
            );
            state.status = "fulfilled";
        },
        [fetchScopesThunk.rejected]: (state) => {
            state.status = "rejected";
        },
        [createScopeThunk.fulfilled]: (state) => {
            state.lastUpdated = +new Date();
        },
        [updateScopeThunk.fulfilled]: (state) => {
            state.lastUpdate = +new Date();
        },
        [deleteScopeThunk.pending]: (state) => {
            state.status = "pending";
        },
        [deleteScopeThunk.fulfilled]: (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                action.payload.forEach(
                    (scope) => {
                        delete state.scopes[scope['scope_id']];
                    }
                );
            }

            state.lastUpdated = + new Date();
            state.status = "fulfilled";
        },
        [deleteScopeThunk.rejected]: (state, action) => {
            state.error = action.payload;
            state.status = "rejected";
        }
    }
});

export default scopeSlice.reducer;