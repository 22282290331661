import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {IPAM_SERVICE} from "../../api-server";
import ssoFetch from "../../utils/sso-fetch";

const initialState = {
    status: 'idle',
    error: null,
    currentContext: null,
    contexts: {}
};

export const fetchContextsThunk = createAsyncThunk(
    'contexts/fetchContexts',
    () =>
        ssoFetch(
            `${IPAM_SERVICE}/contexts`,
            {
                method: 'GET'
            }
        )
);

export const createContextThunk = createAsyncThunk(
    'contexts/createContext',
    (context) =>
        ssoFetch(
            `${IPAM_SERVICE}/contexts`,
            {
                method: 'POST',
                body: JSON.stringify({contextId: context.context_id})
            }
        )
);

export const updateContextThunk = createAsyncThunk(
    'contexts/createContext',
    (context) =>
        ssoFetch(
            `${IPAM_SERVICE}/contexts`,
            {
                method: 'PUT',
                body: JSON.stringify(context)
            }
        )
);

export const deleteContextThunk = createAsyncThunk(
    'contexts/deleteContext',
    async (context) =>
        ssoFetch(
            `${IPAM_SERVICE}/contexts`,

            {
                method: 'DELETE',
                body: JSON.stringify({contextId: context.context_id})
            }
        )
);

const contextSlice = createSlice({
    name: 'contexts',
    initialState,
    reducers: {
        updateCurrentContext(state, action) {
            state.currentContext = action.payload;
        }
    },
    extraReducers: {
        [fetchContextsThunk.pending]: (state) => {
            state.status = "pending";
        },
        [fetchContextsThunk.fulfilled]: (state, action) => {
            state.contexts = action.payload.reduce(
                (obj, context) => ({
                    ...obj,
                    [context['context_id']]: context
                }),
                {} // Empty object to init the reduce function
            );
            state.currentContext = action.payload[0];

            state.status = "fulfilled";
        },
        [fetchContextsThunk.rejected]: (state, action) => {
            state.status = "rejected";
        }
    }
});

export default contextSlice.reducer;
export const {updateCurrentContext} = contextSlice.actions;