import {store} from '../../../store';

export const getBlockState = () => store.getState().networkBlocks;
export const selectNetworkBlocks = (state) => state.networkBlocks.networkBlocks;

export const selectNetworkBlocksWithScope = (scope) => Object
    .values(getBlockState().networkBlocks)
    .filter((block) => (block['scope_id'] === scope.scope_id && !block['parent_block_id']));

export const selectNetworkBlocksWithParent = (item) => Object
    .values(getBlockState().networkBlocks)
    .filter((block) => block['parent_block_id'] === item.block_id);

export const selectBlockDescription = blockId => {
    let block = getBlockState().networkBlocks[blockId];
    if (block) return block.block_desc;
}