import {Form, InputGroup} from "react-bootstrap";

const NumberInput = ({values, text, handleChange, name, disabled = false}) => (
    <InputGroup>
        <InputGroup.Text>{text}</InputGroup.Text>
        <Form.Control
            disabled={disabled}
            name={name}
            key={name}
            value={values[name]}
            type={'number'}
            onChange={handleChange}/>
    </InputGroup>
);

export default NumberInput;