import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import {Provider} from "react-redux";
import {store} from "./store";
import {ReactKeycloakProvider} from '@react-keycloak/web';
import keycloak from "./keycloak";

const keycloakOptions = {
    onLoad: 'login-required',
    checkLoginIframe: false,
    LoadingComponent: () => <h1>loading...</h1>
}

ReactDOM.createRoot(document.getElementById('root'))
    .render(
        <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakOptions}>
            <StrictMode>
                <Provider store={store}>
                    <App/>
                </Provider>
            </StrictMode>
        </ReactKeycloakProvider>
    );