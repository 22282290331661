import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {IPAM_SERVICE} from "../../../api-server";
import ssoFetch from "../../../utils/sso-fetch";
import {buildQuery} from "../../../utils/url-utils";

const initialState = {
    status: 'idle',
    error: null,
    networkBlocks: {},
    lastUpdated: + new Date()
};

export const fetchBlocksThunk = createAsyncThunk(
    'networkBlocks/fetchBlocks',
    (args) =>
        ssoFetch(
            `${IPAM_SERVICE}/network/blocks${buildQuery(args)}`,
            {method: 'GET'}
        )
);

export const createBlockThunk = createAsyncThunk(
    'networkBlocks/createBlock',
    (addressBlock) =>
        ssoFetch(
            `${IPAM_SERVICE}/network/blocks`,
            {
                method: 'POST',
                body: JSON.stringify(addressBlock)
            }
        )
);

export const updateBlockThunk = createAsyncThunk(
    'networkBlocks/updateBlock',
    (addressBlock) => ssoFetch(
        `${IPAM_SERVICE}/network/blocks`,
        {
            method: 'PUT',
            body: JSON.stringify(addressBlock)
        }
    )
);

export const deleteBlockThunk = createAsyncThunk(
    'networkBlocks/deleteBlock',
    (block_id) => ssoFetch(
        `${IPAM_SERVICE}/network/blocks`,
        {method: 'DELETE', body: JSON.stringify({block_id})}
    )
);

export const importBlockThunk = createAsyncThunk(
    'networkBlocks/importBlock',
    (block) => ssoFetch(
        `${IPAM_SERVICE}/network/blocks/import`,
        {
            method: 'POST',
            body: JSON.stringify(block)
        }
    )
);

const network_blocks_slice = createSlice({
    name: 'networkBlocks',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchBlocksThunk.pending]: (state) => {
            state.status = "pending";
        },
        [fetchBlocksThunk.fulfilled]: (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {

                state.networkBlocks = action.payload.reduce(
                    (obj, networkBlock) => ({
                        ...obj,
                        [networkBlock['block_id']]: networkBlock
                    }),
                    state.networkBlocks // Empty object to init the reduce function
                );
            }

            state.status = "fulfilled";
        },
        [fetchBlocksThunk.rejected]: (state, action) => {
            state.error = action.payload;
            state.status = "rejected";
        },
        [deleteBlockThunk.fulfilled]: (state, action) => {
            let newBlocks = {...state.networkBlocks};
            delete newBlocks[action.payload[0]['block_id']];

            return {...state, networkBlocks: newBlocks};
        },
        [createBlockThunk.fulfilled]: (state) => {
            state.lastUpdated = + new Date();
        }
    }
})

export default network_blocks_slice.reducer;