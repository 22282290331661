import {ListGroup, Popover} from "react-bootstrap";
import OutsideAlerter from "../../components/wrappers/OutsideAlerter/OutsideAlerter";

const defaultStyle = props => ({
    position: 'absolute',
    offsetPosition: 'bottom 20px',
    offsetAnchor: 'center',
    width: '18rem',
    top: props.yPos,
    left: props.xPos
});

const ContextMenuItem = props => (
    <ListGroup.Item key={props.key} action onClick={props.onClick} className={'p-0'}>
        {props.icon}
        <p className={'d-inline p-1'}>{props.title}</p>
    </ListGroup.Item>
);

const BasicContextMenu = (props) => (
    <OutsideAlerter callback={() => props.setShowMenu(false)}>
        <Popover style={defaultStyle(props)} onContextMenu={evt => evt.preventDefault()}>
            <Popover.Body className={'p-0'}>
                <ListGroup>
                    {props.menuItems.map(ContextMenuItem)}
                </ListGroup>
            </Popover.Body>
        </Popover>
    </OutsideAlerter>
);

export default BasicContextMenu;