import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {IPAM_SERVICE} from "../../../api-server";
import ssoFetch from "../../../utils/sso-fetch";
import {buildQuery} from "../../../utils/url-utils";

const initialState = {
    status: 'idle',
    error: null,
    networkSubnets: {},
    lastUpdated: +new Date()
};

export const fetchSubnetsThunk = createAsyncThunk(
    'networkSubnets/fetchSubnets',
    (args) =>
        ssoFetch(
            `${IPAM_SERVICE}/network/subnets${buildQuery(args)}`,
            {method: 'GET'}
        )
);

export const createSubnetThunk = createAsyncThunk(
    'networkSubnets/createSubnet',
    (addressSubnet) =>
        ssoFetch(
            `${IPAM_SERVICE}/network/subnets`,
            {method: 'POST', body: JSON.stringify(addressSubnet)}
        )
);

export const updateSubnetThunk = createAsyncThunk(
    'networkSubnets/updateSubnet',
    (addressSubnet) =>
        ssoFetch(
            `${IPAM_SERVICE}/network/subnets`,
            {method: 'PUT', body: JSON.stringify(addressSubnet)}
        )
);

export const deleteSubnetThunk = createAsyncThunk(
    'networkSubnets/deleteSubnet',
    (subnet_id) => ssoFetch(
        `${IPAM_SERVICE}/network/subnets`,
        {method: 'DELETE', body: JSON.stringify({subnet_id})}
    )
);

const network_subnets_slice = createSlice({
    name: 'networkSubnets',
    initialState,
    reducers: {},
    extraReducers: {
        [createSubnetThunk.fulfilled]: (state, action) => {
            state.status = "fulfilled";
            state.lastUpdated = +new Date();
        },
        [fetchSubnetsThunk.pending]: (state, action) => {
            state.status = "pending";
        },
        [fetchSubnetsThunk.fulfilled]: (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {

                state.networkSubnets = action.payload.reduce(
                    (obj, networkSubnet) => ({
                        ...obj,
                        [networkSubnet['subnet_id']]: networkSubnet
                    }),
                    state.networkSubnets // Empty object to init the reduce function
                );
            }

            state.status = "fulfilled";
        },
        [fetchSubnetsThunk.rejected]: (state, action) => {
            state.error = action.payload;
            state.status = "rejected";
        },
        [updateSubnetThunk.pending]: (state, action) => {
            state.status = "pending";
        },
        [updateSubnetThunk.fulfilled]: (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.networkSubnets = action.payload.reduce(
                    (obj, networkSubnet) => ({
                        ...obj,
                        [networkSubnet['subnet_id']]: {
                            ...state.networkSubnets[networkSubnet['subnet_id']],
                            ...networkSubnet
                        }
                    }),
                    state.networkSubnets // Empty object to init the reduce function
                );
            }

            state.status = "fulfilled";
        },
        [updateSubnetThunk.rejected]: (state, action) => {
            state.error = action.payload;
            state.status = "rejected";
        },
        [deleteSubnetThunk.pending]: (state) => {
            state.status = "pending";
        },
        [deleteSubnetThunk.fulfilled]: (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {

                action.payload.forEach(
                    (networkSubnet) => {
                        delete state.networkSubnets[networkSubnet['subnet_id']];
                    }
                );
            }

            state.lastUpdated = +new Date();
            state.status = "fulfilled";
        },
        [deleteSubnetThunk.rejected]: (state, action) => {
            state.error = action.payload;
            state.status = "rejected";
        }
    }
})

export default network_subnets_slice.reducer;