import React from "react";
import BsCell from "./BsCell";

const BsRow = (prepareRow) => (row) => {
    prepareRow(row); // Must be run prior to rendering to prepare the row props.
    return (
        <tr {...row.getRowProps()}>
            {row.cells.map(BsCell)}
        </tr>
    )
};

export default BsRow;