import {BsBox} from "react-icons/bs";
import {FaFolderPlus} from "react-icons/fa";
import BasicContextMenu from "./BasicContextMenu";

const onMenuItemClick = (key, props) => () => {
    props.setShowMenu(false);
    props.setActiveModal(key);
}

const menuItems = props => ([
    {
        key: 'new-scope',
        title: 'New Scope',
        icon: <FaFolderPlus color={'green'}/>,
        onClick: onMenuItemClick('new-scope', props),
        allowedTypes: ['root', 'scope']
    },
    {
        key: 'new-block',
        title: 'New Block',
        icon: <BsBox color={'green'}/>,
        onClick: onMenuItemClick('new-block', props),
        allowedTypes: ['block4', 'block6', 'scope']
    }
]);

const ScopeContextMenu = (props) => {
    if (props.showMenu) {
        return BasicContextMenu({
            ...props,
            menuItems: menuItems(props)
        });
    } else {
        return null;
    }
};

export default ScopeContextMenu;