import {Form, InputGroup} from "react-bootstrap";

const DefaultGatewayInput = ({values, errors, handleChange}) => (
    <InputGroup hasValidation>
        <InputGroup.Text>Default Gateway</InputGroup.Text>
        <Form.Control
            key={"default_gateway_control"}
            name={"defaultGateway"}
            isValid={values.defaultGateway && !errors.defaultGateway}
            isInvalid={!!errors.defaultGateway}
            value={values.defaultGateway}
            onChange={handleChange}/>
        <Form.Control.Feedback type="invalid">
            {errors.defaultGateway}
        </Form.Control.Feedback>
    </InputGroup>
);

export default DefaultGatewayInput;