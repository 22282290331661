import BasicContextMenu from "./BasicContextMenu";
import {FaFolderPlus} from "react-icons/fa";

const onMenuItemClick = (key, props) => () => {
    props.setShowMenu(false);
    props.setActiveModal(key);
}

const menuItems = props => ([
    {
        key: 'new-scope',
        title: 'New Scope',
        icon: <FaFolderPlus color={'green'}/>,
        onClick: onMenuItemClick('new-scope', props),
        allowedTypes: ['root', 'scope']
    }
]);

const RootContextMenu = (props) => {
    if (props.showMenu) {
        return BasicContextMenu({
            ...props,
            menuItems: menuItems(props)
        })
    } else {
        return null;
    }
}

export default RootContextMenu;