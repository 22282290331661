import {Col, Form, InputGroup, Row} from "react-bootstrap";
import React from "react";
import StringInput from "../../inputs/StringInput/StringInput";
import NumberInput from "../../inputs/NumberInput/NumberInput";
import {getReverseZones} from "../../../utils/zones/zones";

const ZoneNameInput = (zone) => (
    <Row>
        <Col>
            <InputGroup>
                <InputGroup.Text>Zone Name</InputGroup.Text>
                <Form.Control
                    disabled
                    key={'reverseZone'}
                    value={zone}
                    type={'string'}/>
            </InputGroup>
        </Col>
    </Row>
);

const ReverseZoneForm = (props) => {
    let reverseZones = getReverseZones(props.values.networkAddress, props.values.cidr);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <InputGroup hasValidation>
                        <Form.Check>
                            <Form.Check.Input type={'checkbox'}
                                              key={'createReverseCheckbox'}
                                              isInvalid={!!props.errors.createReverseZone}
                                              checked={props.values.createReverseZone}
                                              onChange={() => props.setFieldValue('createReverseZone', !props.values.createReverseZone)}/>
                            <Form.Check.Label key={'label'} className={"px-2"}>
                                Create Reverse Zone{reverseZones.length > 1 && 's'}
                            </Form.Check.Label>
                            <Form.Control.Feedback key={"feedback"} type="invalid">
                                {props.errors.createReverseZone}
                            </Form.Control.Feedback>
                        </Form.Check>
                    </InputGroup>
                </Col>
            </Row>
            {
                props.values.createReverseZone && (
                    <React.Fragment>
                        {reverseZones.map(ZoneNameInput)}
                        <Row>
                            <Col>
                                <StringInput text={'Master'} name={'mname'} {...props} />
                            </Col>
                            <Col>
                                <StringInput text={'Email'} name={'rname'} {...props} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text>Refresh</InputGroup.Text>
                                    <Form.Control
                                        name={'refresh'}
                                        key={'refresh'}
                                        value={props.values.refresh}
                                        type={'number'}
                                        onChange={props.handleChange}/>
                                </InputGroup>
                            </Col>
                            <Col>
                                <NumberInput text={'Retry'} name={'retry'} {...props} />
                            </Col>
                            <Col>
                                <NumberInput text={'Expire'} name={'expire'} {...props} />
                            </Col>
                            <Col>
                                <NumberInput text={'TTL'} name={'ttl'} {...props} />
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
};

export default ReverseZoneForm;