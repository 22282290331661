import React from "react";
import {store} from "../../../store";
import {Toast} from "react-bootstrap";
import {deleteToast} from "../../../store/toasts/toasts_slice";
import {BsFillExclamationTriangleFill} from "react-icons/bs";

const toastIcon = (type) => {
    switch (type) {
        case "warning": {
            return <BsFillExclamationTriangleFill size={'1.25rem'} className={'text-warning'}/>
        }
        case "error": {
            return <BsFillExclamationTriangleFill size={'1.25rem'} className={'text-danger'}/>
        }
        default: {
            return <BsFillExclamationTriangleFill size={'1.25rem'} color={'red'}/>
        }
    }
};

const ErrorToast = (toast) => (
    <Toast key={`toast-${toast.key}`}
           autohide delay={20000}
           onClose={() => store.dispatch(deleteToast(toast.key))}>
        <Toast.Header>
            {toastIcon(toast.type)}
            <strong className="m-auto">{toast.title}</strong>
        </Toast.Header>
        <Toast.Body>{toast.message}</Toast.Body>
    </Toast>
);

export default ErrorToast;