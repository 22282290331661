import {Container, Col, Row} from "react-bootstrap";
import {
    AddressBlockInput,
    AddressFamilyInput,
    DefaultGatewayInput,
    DescriptionInput,
    ParentBlockInput
} from "../../inputs";
import React from "react";
import ReverseZoneForm from "../ReverseZoneForm/ReverseZoneForm";

const SubnetForm = (props) => (
    <Container fluid>
        <Row className={"mb-3"}>
            <Col>
                <ParentBlockInput {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <AddressFamilyInput {...props} disabled={true}/>
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <AddressBlockInput {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <DefaultGatewayInput {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <DescriptionInput {...props} />
            </Col>
        </Row>
        <ReverseZoneForm {...props} />
    </Container>
);

export default SubnetForm;