import React from 'react'
import {useTable, useSortBy, usePagination, useGlobalFilter} from "react-table";
import BsHeaderGroup from "./BsHeaderGroup";
import BsRow from "./BsRow";
import BsTablePagination from "./BsTablePagination";
import BsTableToolbar from "./BsTableToolbar";
import {Table, Col, Row} from "react-bootstrap";

const getPaginationProps = (instance) => ({
    page: instance.page,
    pageSize: instance.state.pageSize,
    pageIndex: instance.state.pageIndex,
    pageCount: instance.pageCount,
    pageOptions: instance.pageOptions,
    gotoPage: instance.gotoPage,
    nextPage: instance.nextPage,
    previousPage: instance.previousPage,
    setPageSize: instance.setPageSize,
    canPreviousPage: instance.canPreviousPage,
    canNextPage: instance.canNextPage
})

const BsTable = ({
                     columns,
                     data,
                     getColumnProps = () => {
                     },
                     addHandler,
                     onDeleteRow,
                     initialState,
                     addDisabled = false,
                     toolbar = true,
                     pagination = true
                 }) => {

    // Create the react-table instance
    const tableInstance = useTable(
        {
            columns,
            data,
            initialState
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // General table properties
    const {
        getTableProps, headerGroups, page, prepareRow
    } = tableInstance;

    // Render the UI for your table
    return (
        <>
            {toolbar && (<BsTableToolbar setGlobalFilter={tableInstance.setGlobalFilter}
                                         globalFilter={tableInstance.state.globalFilter || ''}
                                         addDisabled={addDisabled}
                                         addHanlder={addHandler}/>)}
            <Row>
                <Col>
                    <Table hover size="sm" {...getTableProps()}>
                        <thead>
                        {headerGroups.map(BsHeaderGroup({getColumnProps}))}
                        </thead>
                        <tbody>
                        {page.map(BsRow(prepareRow, tableInstance.state))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {pagination && (<BsTablePagination idPrefix={"bs-table"} {...getPaginationProps(tableInstance)} />)}
        </>
    );
};

export {BsTable};