import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {IPAM_SERVICE} from "../../../api-server";
import ssoFetch from "../../../utils/sso-fetch";
import {buildQuery} from "../../../utils/url-utils";

const initialState = {
    status: 'idle',
    error: null,
    networkAddresses: {},
    lastUpdated: + new Date()
};

export const fetchAddressesThunk = createAsyncThunk(
    'networkAddresses/fetchAddresses',
    (args) => {
        return ssoFetch(
            `${IPAM_SERVICE}/network/addresses${buildQuery(args)}`,
            {method: 'GET'}
        )
    }
);

export const fetchAddressesNoUpdateThunk = createAsyncThunk(
    'networkAddresses/fetchAddressesNoUpdate',
    (args) => {
        return ssoFetch(
            `${IPAM_SERVICE}/network/addresses${buildQuery(args)}`,
            {method: 'GET'}
        )
    }
);

export const createAddressThunk = createAsyncThunk(
    'networkAddresses/createAddress',
    (addressAddress) =>
        ssoFetch(
            `${IPAM_SERVICE}/network/addresses`,
            {method: 'POST', body: JSON.stringify(addressAddress)}
        )
);

export const createMappedAddressThunk = createAsyncThunk(
    'networkAddresses/createMappedAddress',
    (addressAddress) =>
        ssoFetch(
            `${IPAM_SERVICE}/network/addresses/mapped`,
            {method: 'POST', body: JSON.stringify(addressAddress)}
        )
);

export const deleteAddressThunk = createAsyncThunk(
    'networkAddresses/deleteAddress',
    (address_id) =>
        ssoFetch(
            `${IPAM_SERVICE}/network/addresses`,
            {method: 'DELETE', body: JSON.stringify({address_id})}
        )
);

const network_addresses_slice = createSlice({
    name: 'networkAddresses',
    initialState,
    reducers: {},
    extraReducers: {
        [createAddressThunk.fulfilled]: (state) => {
            state.lastUpdated = + new Date();
        },
        [fetchAddressesThunk.pending]: (state, action) => {
            state.status = "pending";
        },
        [fetchAddressesThunk.fulfilled]: (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {

                state.networkAddresses = action.payload.reduce(
                    (obj, networkAddress) => ({
                        ...obj,
                        [networkAddress['address_id']]: networkAddress
                    }),
                    state.networkAddresses // Empty object to init the reduce function
                );
            }

            state.status = "fulfilled";
        },
        [fetchAddressesThunk.rejected]: (state, action) => {
            state.error = action.payload;
            state.status = "rejected";
        },
        [deleteAddressThunk.fulfilled]: (state, action) => {
            delete state.networkAddresses[action.meta.arg];
            state.lastUpdated = + new Date();
        }
    }
})

export default network_addresses_slice.reducer;