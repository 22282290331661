import React from "react";
import {FormControl, InputGroup} from "react-bootstrap";

const ScopeInput = ({values, handleChange, disabled = true, text = 'Scope'}) => {
    return (
        <InputGroup>
            <InputGroup.Text>{text}</InputGroup.Text>
            <FormControl name={"scope"}
                         disabled={disabled}
                         onChange={handleChange}
                         value={values.scope}
                         aria-label={"scope"}/>
        </InputGroup>
    )
};

export default ScopeInput;