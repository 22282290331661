import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    toastObjects: {}
};

const toastSlice = createSlice({
    name: 'scopeTree',
    initialState,
    reducers: {
        addToast(state, action) {
            let key = Math.random().toString(36).slice(2);
            state.toastObjects[key] = {...action.payload, key};
        },
        deleteToast(state, action) {
            delete state.toastObjects[action.payload];
        }
    }
});

export default toastSlice.reducer;
export const {addToast, deleteToast} = toastSlice.actions;