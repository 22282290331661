import React from "react";
import {store} from "../../store";
import {Formik} from "formik";
import {parse} from "ipaddr.js";
import {addToast} from "../../store/toasts/toasts_slice";
import {updateBlockThunk} from "../../store/network/blocks/network_blocks_slice";
import {selectBlockDescription} from "../../store/network/blocks/network_blocks_selectors";
import {FormikModal} from "../index";
import {Col, Modal, Row} from "react-bootstrap";
import {
    AddressBlockInput,
    DescriptionInput,
    ScopeSelect
} from "../../components/inputs";

const getInitialValues = item => {return{
    scope_id: item.scope_id,
    block_id: item.block_id,
    blockType: 'Subnet',
    addressFamily: `IPv${item && item.address_family ? item.address_family : '4'}`,
    networkAddress: item && item.itemType === 'block' && item.network_address ? item.network_address.split('/')[0] : '',
    cidr: 24,
    description: selectBlockDescription(item.block_id)
}};

const buildBlockPayload = ({scope_id, block_id, blockType, networkAddress, description, cidr}) => {
    return {
        scope_id: scope_id,
        block_id: block_id,
        block_type: blockType,
        network_address: `${parse(networkAddress).toString()}/${cidr}`,
        block_desc: description,
        properties: {
            "zoneValidationDisabled": false
        }
    }
}

const onEditBlockSubmit = ({setActiveModal}) => (values, {resetForm}) => {
    store.dispatch(updateBlockThunk(buildBlockPayload(values)))
        .unwrap()
        .then(() => {
            store.dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Block '${values.networkAddress}/${values.cidr}' has been updated`
            }));
        })
        .catch(error => {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Unable to Update Block',
                message: error.message
            }));
        })
        .finally(() => {
            setActiveModal();
            resetForm();
        });
};

const formValidator = ({description}) => {
    const errors = {};

    if (description !== '') {

    }

    return errors;
};

const FormBody = props => (
    <React.Fragment>
        <Row className={"mb-3"}>
            <Col>
                <AddressBlockInput {...props} hasValidation={false} disabled={true}/>
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <DescriptionInput {...props} />
            </Col>
        </Row>
    </React.Fragment>
);

const AddressBlockEditor = ({pathItem, activeModal, setActiveModal}) => (
    <Formik validateOnBlur
            enableReinitialize
            initialValues={getInitialValues(pathItem)}
            validate={formValidator}
            onSubmit={onEditBlockSubmit({setActiveModal})}>
        {
            FormikModal({
                show: activeModal === 'edit-block',
                onHide: () => setActiveModal(),
                onCancel: () => setActiveModal(),
                modalBody: FormBody,
                modalHeader: () => <Modal.Title>Create Block</Modal.Title>
            })
        }
    </Formik>
);

export default AddressBlockEditor;