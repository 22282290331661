import {Form, InputGroup} from "react-bootstrap";

const AddressInput = ({values, errors, handleChange, disabled = false}) => (
    <InputGroup hasValidation>
        <InputGroup.Text>IP Address</InputGroup.Text>
        <Form.Control
            disabled={disabled}
            key={"control"}
            name={"address"}
            isValid={values.address && !errors.address}
            isInvalid={!!errors.address}
            value={values.address}
            onChange={handleChange}
            placeholder={(values.addressFamily === 'IPv4') ? '192.168.0.0' : 'fe80::'}/>
        <Form.Control.Feedback key={"feedback"} type="invalid">
            {errors.address}
        </Form.Control.Feedback>
    </InputGroup>
);

export default AddressInput;