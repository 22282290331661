import {store} from '../../store';
import {getReverseRecord, getReverseZones} from "../../../utils/zones/zones";

const reverseRegex = /(.*).arpa/;

const getDomainState = () => store.getState().domains;

const selectDomain = (domainState, domainId) => domainState.domains[domainId];
const selectDomainWithName = (hostname) => {
    let retDomain;
    Object.values(getDomainState().domains).forEach(domain => {
        if (hostname.startsWith(domain.name)) {
            if (retDomain === null || domain.name.length > retDomain.name.length) {
                retDomain = domain;
            }
        }
    });
    return retDomain;
};
const selectDomains = domainState => {
    if (Object.keys(domainState.domains).length > 0) {
        return Object.values(domainState.domains)
            .filter((domain) => !reverseRegex.test(domain.name));
    } else {
        return [];
    }
};

// const selectReverseDomains = domainState => {
//     if (Object.keys(domainState.domains).length > 0) {
//         return Object.values(domainState.domains)
//             .filter((domain) => reverseRegex.test(domain.name));
//     } else {
//         return [];
//     }
// };

const hasReverseZones = (address, cidr) => {
    let expectedZones = getReverseZones(address, cidr);
    let foundZones = [];
    let state = getDomainState();

    expectedZones.forEach(zoneName => {
        if (state.nameMap[zoneName]) {
            foundZones.push(zoneName);
        }
    });

    return JSON.stringify(expectedZones) === JSON.stringify(foundZones);
};

const selectReverseZones = (address, cidr) => {
    let expectedZones = getReverseZones(address, cidr);
    let foundZones = [];
    let state = getDomainState();


    expectedZones.forEach(zoneName => {
        let domainId = state.nameMap[zoneName];
        if (domainId) {
            foundZones.push(state.domains[domainId]);
        }
    });

    return foundZones;
};

const selectReverseZone = (address) => {
    let reverseRecord = getReverseRecord(address);
    let domainState = getDomainState();

    let foundZone = "";

    Object.keys(domainState.nameMap).forEach(name => {
        if (reverseRecord.endsWith(name)) {
            foundZone = name.length > foundZone.length ? name: foundZone;
        }
    });

    return selectDomain(domainState, domainState.nameMap[foundZone]);
};

const isFulfilled = domains => domains.status === 'fulfilled';

export {selectDomains, selectDomainWithName, selectDomain, isFulfilled, hasReverseZones, selectReverseZone, selectReverseZones}