import React from "react";
import {store} from "../../store";
import {Formik} from "formik";
import {addToast} from "../../store/toasts/toasts_slice";
import {FormikModal} from "../index";
import {Modal} from "react-bootstrap";
import {createScopeThunk} from "../../store/scopes";
import {ScopeForm} from "../../components/forms";

const ScopeNameRegex = /^[A-Za-z0-9]*(_[A-Za-z0-9]*)*$/;

const getInitialValues = item => {return {
    parentScopeId: item && item.scope_id,
    scopeName: '',
    description: ''
}};

const buildScopePayload = ({scopeName, description}, scope) => {
    return {
        scope_name: scopeName,
        scope_desc: description,
        parent_scope_id: scope && scope.scope_id,
        context_id: store.getState().contexts.currentContext.context_id
    }
};

const onNewScopeSubmit = ({scope, setActiveModal}) => (values, {resetForm}) => {
    store.dispatch(createScopeThunk(buildScopePayload(values, scope)))
        .unwrap()
        .then(() => {
            store.dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Scope '${values.scopeName}' has been created`
            }));
        })
        .catch(error => {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Unable to Create Scope',
                message: error.message
            }));
        })
        .finally(() => {
            resetForm();
            setActiveModal();
        });
};

const formValidator = ({scopeName, description}) => {
    const errors = {};

    if (!scopeName) {
        errors.scopeName = 'Required';
    }

    if (!ScopeNameRegex.test(scopeName)) {
        errors.scopeName = 'Only alphanumeric characters and underscores allowed. A-Z a-z 0-9 _';
    }

    if (!description) {
        errors.description = 'Required';
    }

    return errors;
};

const ScopeCreator = ({scope, activeModal, setActiveModal}) => (
    <Formik validateOnBlur
            enableReinitialize
            initialValues={getInitialValues(scope)}
            validate={formValidator}
            onSubmit={onNewScopeSubmit({scope, setActiveModal})}>
        {
            FormikModal({
                show: activeModal === 'new-scope',
                onHide: () => setActiveModal(),
                onCancel: () => setActiveModal(),
                modalBody: ScopeForm,
                modalHeader: () => <Modal.Title>New Scope</Modal.Title>
            })
        }
    </Formik>
);

export default ScopeCreator;