import React from 'react';
import {
    Col, Row,
    FormControl, InputGroup,
    Button, ButtonGroup
} from "react-bootstrap";
import {BsFillPencilFill, BsPlus, BsPlusLg, BsSearch, BsTrash} from "react-icons/bs";

class BsTableToolbar extends React.Component {

    render() {
        return (
            <Row className={"mb-2 justify-content-between"}>
                <Col className={"col-4"}>
                    <InputGroup>
                        <InputGroup.Text><BsSearch /></InputGroup.Text>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="search-button"
                            value={this.props.globalFilter}
                            onChange={e => {
                                this.props.setGlobalFilter(e.target.value || '');
                            }}
                        />
                    </InputGroup>
                </Col>
            </Row>
        );
    }

    iconButtonCreator = (icon, props) => (
        <Button variant={props.variant}
                {...props}>
            {icon}
        </Button>
    );

}

export default BsTableToolbar;