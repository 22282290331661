import {Button, Form, InputGroup} from "react-bootstrap";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {store} from "../../store";
import {fetchContextsThunk, updateCurrentContext} from "../../store/contexts/contexts_slice";
import {isFulfilled, selectContextWithName} from "../../store/contexts/contexts_selectors";
import {clearPath} from "../../store/path/path_slice";
import {BsPlusLg} from "react-icons/bs";


const SelectorOption = (context) => (
    <option key={context['context_id']}>
        {context['context_name']}
    </option>
);


const onChangeHandler = (contexts) => (event) => {
    let context = selectContextWithName(contexts, event.target.value);
    store.dispatch(updateCurrentContext(context[0]));
    store.dispatch(clearPath());
}

const ContextSelector = (props) => {

    useEffect(() => {
        if (!isFulfilled(store.getState())) {
            store.dispatch(fetchContextsThunk());
        }
    }, []);

    return (
        <InputGroup key={"toolbar-search"} className={'mt-1 mb-1'}>
            <Form.Select aria-label="Context"
                         value={props.contexts.currentContext ? props.contexts.currentContext.context_name : 'Select Context'}
                         onChange={onChangeHandler(props.contexts)}>
                {Object.values(props.contexts.contexts).map(SelectorOption)}
            </Form.Select>
        </InputGroup>
    );
}

const mapStateToProps = (state) => ({
    contexts: state.contexts
});

export default connect(mapStateToProps)(ContextSelector);