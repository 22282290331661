import React from "react";
import {Form, InputGroup} from "react-bootstrap";

const AddressBlockInput = ({values, errors, handleChange, disabled = false, validFeedback = '', hasValidation = true}) => (
    <InputGroup hasValidation={hasValidation}>
        <InputGroup.Text>Network Address</InputGroup.Text>
        <Form.Control
            disabled={disabled}
            key={"control"}
            name={"networkAddress"}
            isValid={values.networkAddress && !errors.networkAddress}
            isInvalid={!!errors.networkAddress}
            value={values.networkAddress}
            onChange={handleChange}
            placeholder={(values.addressFamily === 'IPv4') ? '192.168.0.0' : 'fe80::'}/>
        <InputGroup.Text>/</InputGroup.Text>
        <Form.Control
            disabled={disabled}
            key={"select"}
            name={"cidr"}
            type={"number"}
            isValid={values.cidr && !errors.cidr}
            isInvalid={!!errors.cidr}
            style={{maxWidth: '15%'}}
            placeholder={(values.addressFamily === 'IPv4') ? 24 : 48}
            value={values.cidr ? values.cidr : 24}
            onChange={handleChange}>
        </Form.Control>
        <Form.Control.Feedback key={"valid-feedback"} type="valid">
            {validFeedback}
        </Form.Control.Feedback>
        <Form.Control.Feedback key={"feedback"} type="invalid">
            {errors.networkAddress}
        </Form.Control.Feedback>
        <Form.Control.Feedback key={"feedback-cidr"} type="invalid" className={"text-end"}>
            {errors.cidr}
        </Form.Control.Feedback>
    </InputGroup>
);

export default AddressBlockInput;