import React from 'react';
import {Pagination} from 'react-bootstrap';

class EnhancedTablePagination extends React.Component {

    render = () => (
        <React.Fragment>
            <Pagination className={"mb-0 justify-content-center"}>
                {this.renderPageItems()}
            </Pagination>
        </React.Fragment>
    );

    renderPageItems = () => {
        let {idPrefix,
            pageCount, pageIndex,
            gotoPage, nextPage, previousPage,
            canPreviousPage, canNextPage
        } = this.props;

        let pageItems = [];

        pageItems.push([
            <Pagination.First key={`${idPrefix}-pagination-first`}
                              disabled={pageIndex === 0}
                              onClick={() => gotoPage(0)}/>,
            <Pagination.Prev key={`${idPrefix}-pagination-prev`}
                             disabled={!canPreviousPage}
                             onClick={previousPage}/>
        ]);

        for (let i = 0; i < pageCount; i++) {
            pageItems.push(
                <Pagination.Item key={`${idPrefix}-pagination-${i}`}
                                 active={pageIndex === i}
                                 onClick={() => gotoPage(i)}>
                    {i + 1}
                </Pagination.Item>
            );
        }

        pageItems.push([
            <Pagination.Next key={`${idPrefix}-pagination-next`}
                             disabled={!canNextPage}
                             onClick={nextPage}/>,
            <Pagination.Last key={`${idPrefix}-pagination-last`}
                             disabled={pageIndex === (pageCount - 1)}
                             onClick={() => gotoPage(pageCount - 1)}/>
        ]);

        return pageItems;
    }

}

export default EnhancedTablePagination;