import React from "react";
import {Dropdown, Card, Col, Row} from "react-bootstrap";
import {BsFolder, BsThreeDotsVertical} from "react-icons/bs";
import ScopeEditorModal from "../../../modals/ScopeEditor/ScopeEditorModal";
import ScopeDeleteModal from "../../../modals/ScopeDelete/ScopeDeleteModal";
import ScopeCreator from "../../../modals/ScopeCreator/ScopeCreator";
import AddressBlockCreator from "../../../modals/AddressBlockCreator/AddressBlockCreator";

const ScopeDetail = ({scope, setActiveModal, activeModal}) => (
    <React.Fragment>
        <Row className={'mb-3'}>
            <Col className={'col-lg-12'}>
                <Card className={'shadow'}>
                    <Card.Header className={'bg-white'}>
                        <Dropdown className={'ms-2 float-end'}>
                            <Dropdown.Toggle id={"dropdown-basic"}
                                             size={"sm"}
                                             variant={"outline-light"}
                                             className={'border-0 no-after'}
                                             style={{
                                                 borderRadius: '15px'
                                             }}>
                                <BsThreeDotsVertical className={'text-black'} fontSize={'1rem'}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setActiveModal('edit-scope')}>
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setActiveModal('move-scope')}>
                                    Move
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setActiveModal('delete-scope')}>
                                    Delete
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Card.Title>
                            <BsFolder/> {scope ? scope.scope_name : ''}
                        </Card.Title>
                        <Card.Subtitle>{scope ? scope.scope_desc : ''}</Card.Subtitle>
                    </Card.Header>
                    <Card.Body>

                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {scope &&
            [
                <ScopeCreator key={'scope-creator-modal'}
                              scope={scope}
                              activeModal={activeModal}
                              setActiveModal={setActiveModal}/>,
                <ScopeEditorModal key={'scope-editor-modal'}
                                  scope={scope}
                                  activeModal={activeModal}
                                  setActiveModal={setActiveModal}/>,
                <ScopeDeleteModal key={'scope-delete-modal'}
                                  scope={scope}
                                  activeModal={activeModal}
                                  setActiveModal={setActiveModal}/>,
                <AddressBlockCreator
                    key={'block-creator-modal'}
                    pathItem={scope}
                    activeModal={activeModal}
                    setActiveModal={setActiveModal}/>
            ]
        }
    </React.Fragment>
);

export default ScopeDetail;