import React from 'react';
import {Breadcrumb} from "react-bootstrap";
import {connect} from "react-redux";
import {PathButton} from "./PathButton";
import {store} from "../../store";
import {clearPath, gotoItem} from "../../store/path/path_slice";
import {BsBox, BsDiagram3, BsDot, BsFolder, BsLayers, BsQuestion} from "react-icons/bs";

const IconDiv = (icon, value) => (
    <p className={'m-0 text-nowrap text-light'}>{icon} {value}</p>
);

const CrumbItem = ({index, active, value, icon}) => {
    return (
        <Breadcrumb.Item key={`item_${index}`}
                         active={active}
                         linkAs={PathButton}
                         linkProps={{
                             value: IconDiv(icon, value),
                             className: 'text-light',
                             onClick: () => store.dispatch(gotoItem(index + 1))
                         }}>
            <i>{IconDiv(icon, value)}</i>
        </Breadcrumb.Item>
    );
}


const Crumb = (item, i, path) => {
    switch (item.itemType) {
        case "scope": {
            return CrumbItem({
                index: i,
                active: i + 1 === path.length,
                value: item.scope_name,
                icon: <BsFolder style={{fontSize: '.85rem'}}/>
            });
        }
        case "block": {
            return CrumbItem({
                index: i,
                active: i + 1 === path.length,
                value: item.network_address,
                icon: <BsBox style={{fontSize: '.85rem'}}/>
            });
        }
        case "subnet": {
            return CrumbItem({
                index: i,
                active: i + 1 === path.length,
                value: item.network_address,
                icon: <BsDiagram3 style={{fontSize: '.85rem'}}/>
            });
        }
        case "address": {
            return CrumbItem({
                index: i,
                active: i + 1 === path.length,
                value: item.address,
                icon: <BsDot style={{fontSize: '.85rem'}}/>
            });
        }
        default: {
            return CrumbItem({
                index: i,
                active: i + 1 === path.length,
                value: item.network_address,
                icon: <BsQuestion style={{fontSize: '.85rem'}}/>
            });
        }
    }

};

const PathView = ({path, contexts}) => {
    return (
        <Breadcrumb listProps={{className: "mb-2 mt-2"}}>
            <Breadcrumb.Item linkAs={PathButton}
                             key={'item_0'}
                             active={path.length === 0}
                             linkProps={{
                                 value: IconDiv(
                                     <BsLayers style={{fontSize: '.85rem'}}/>,
                                     contexts.currentContext && contexts.currentContext.context_name
                                 ),
                                 className: 'text-light',
                                 onClick: () => {
                                     store.dispatch(clearPath())
                                 }
                             }}>
                {
                    IconDiv(
                        <BsLayers style={{fontSize: '.85rem'}}/>,
                        contexts.currentContext ? contexts.currentContext.context_name : ''
                    )
                }
            </Breadcrumb.Item>
            {path.length > 0 && path.map(Crumb)}
        </Breadcrumb>
    )
};

const mapStateToProps = (state) => ({
    path: state.path.path,
    contexts: state.contexts,
    scopes: state.scopes
});

export default connect(mapStateToProps)(PathView);