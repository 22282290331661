import {FormControl, InputGroup} from "react-bootstrap";

const SubnetInput = ({values, handleChange}) => {
    return (
        <InputGroup>
            <InputGroup.Text>Subnet</InputGroup.Text>
            <FormControl name={"subnet"}
                         disabled
                         onChange={handleChange}
                         value={values.item.network_address || ''}
                         aria-label={"subnet"}/>
        </InputGroup>
    )
};

export default SubnetInput;