import {Form, FormControl, InputGroup} from "react-bootstrap";

const HostnameInput = ({values, errors, handleChange}) => (
    <InputGroup hasValidation>
        <InputGroup.Text>Hostname</InputGroup.Text>
        <FormControl name={"hostname"}
                     onChange={handleChange}
                     value={values.hostname}
                     aria-label={"hostname"}
                     isValid={values.hostname && !errors.hostname}
                     isInvalid={!!errors.hostname}/>
        <Form.Control.Feedback type="invalid">
            {errors.hostname}
        </Form.Control.Feedback>
    </InputGroup>
);

export default HostnameInput;