import React from "react";
import {store} from "../../store";
import {Formik} from "formik";
import {addToast} from "../../store/toasts/toasts_slice";
import {FormikModal} from "../index";
import {Col, Modal, Row} from "react-bootstrap";
import {updateSubnetThunk} from "../../store/network/subnets/network_subnets_slice";
import {DefaultGatewayInput, DescriptionInput} from "../../components/inputs";

const getInitialValues = pathItem => {
    return {
        subnet_id: pathItem.subnet_id,
        pathItem: pathItem,
        addressFamily: `IPv${pathItem && pathItem.address_family ? pathItem.address_family : '4'}`,
        networkAddress: pathItem && pathItem.network_address ? pathItem.network_address.split('/')[0] : '',
        cidr: pathItem && pathItem.name ? pathItem.name.split('/')[1] : '24',
        defaultGateway: pathItem.default_gateway || '',
        description: pathItem.subnet_desc || '',
        createReverseZone: false,
        mname: 'ns1.acd.net.',
        rname: 'webmaster.acd.net.',
        refresh: 86400,
        retry: 7200,
        expire: 3600000,
        ttl: 86400
    }
};

const buildBlockPayload = ({subnet_id, description, defaultGateway}) => {
    return {
        subnet_id: subnet_id,
        subnet_desc: description,
        default_gateway: defaultGateway ? defaultGateway : null
    }
}

const onEditBlockSubmit = ({setActiveModal}) => (values, {resetForm}) => {
    store.dispatch(updateSubnetThunk(buildBlockPayload(values)))
        .unwrap()
        .then(() => {
            store.dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Subnet '${values.networkAddress}/${values.cidr}' has been updated`
            }));
        })
        .catch(error => {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Unable to Update Block',
                message: error.message
            }));
        })
        .finally(() => {
            setActiveModal();
            resetForm();
        });
};

const formValidator = ({description}) => {
    const errors = {};

    if (description !== '') {

    }

    return errors;
};

const FormBody = props => (
    <React.Fragment>
        <Row className={"mb-3"}>
            <Col>
                <DefaultGatewayInput {...props} hasValidation={false} disabled={true}/>
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <DescriptionInput {...props} />
            </Col>
        </Row>
    </React.Fragment>
);

const SubnetEditor = ({pathItem, activeModal, setActiveModal}) => (
    <Formik validateOnBlur
            enableReinitialize
            initialValues={getInitialValues(pathItem)}
            validate={formValidator}
            onSubmit={onEditBlockSubmit({setActiveModal})}>
        {
            FormikModal({
                show: activeModal === 'edit-subnet',
                onHide: () => setActiveModal(),
                onCancel: () => setActiveModal(),
                modalBody: FormBody,
                modalHeader: () => <Modal.Title>Edit Subnet</Modal.Title>
            })
        }
    </Formik>
);

export default SubnetEditor;