import React from "react";
import {store} from "../../store";
import {Formik} from "formik";
import {IPv4, IPv6, parse} from "ipaddr.js";
import {addToast} from "../../store/toasts/toasts_slice";
import {FormikModal} from "../index";
import {Modal} from "react-bootstrap";
import {hostnameRegex} from "../../utils/regex/hostname";
import {selectDomainWithName, selectReverseZone} from "../../store/dns/domains/DomainSelectors";
import {getReverseRecord, getZones} from "../../utils/zones/zones";
import ssoFetch from "../../utils/sso-fetch";
import {DNS_SERVICE} from "../../api-server";
import {AddressForm} from "../../components/forms";
import {createAddressThunk} from "../../store/network/addresses/network_addresses_slice";
import {createRecordThunk} from "../../store/dns/records/RecordSlice";

const getInitialValues = item => ({
    address_id: null,
    item: item,
    addressFamily: `IPv${item && item.address_family ? item.address_family : '4'}`,
    networkAddress: item && item.network_address ? item.network_address.split('/')[0] : '',
    cidr: item && item.network_address ? item.network_address.split('/')[1] : '24',
    address: '',
    description: '',
    hostname: '',
    createAddressRecord: false,
    createReverseRecord: false
});

const buildAddressPayload = ({item, address, description, hostname}) => {
    return {
        subnet_id: item.subnet_id,
        address: parse(address).toString(),
        address_desc: description,
        hostname: hostname ? hostname : null
    };
}

const buildRecordPayload = ({
                                name,
                                content,
                                type = 'A',
                                ttl = 86400,
                                prio = 0,
                                disabled = false,
                                auth = 1
                            }) => (
    {name, content, type, ttl, prio, disabled, auth}
);


const createAddressRecord = (dispatch, {hostname, address, addressFamily}) => {
    let payload = buildRecordPayload({
        name: hostname,
        content: address,
        type: addressFamily === 'IPv6' ? 'AAAA' : 'A'
    });

    dispatch(createRecordThunk(payload))
        .unwrap()
        .then(() => {
            dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Address record for '${hostname}' has been created`
            }));
        })
        .catch(error => {
            dispatch(addToast({
                type: 'warning',
                title: `Unable to create ${addressFamily === 'IPv6' ? 'AAAA' : 'A'} record`,
                message: error.message
            }));
        });
};

const createReverseRecord = (dispatch, {hostname, address}) => {
    let payload = buildRecordPayload({
        name: getReverseRecord(address),
        content: hostname,
        type: 'PTR'
    });

    dispatch(createRecordThunk(payload))
        .unwrap()
        .then(() => {
            dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Reverse record for '${address}' has been created`
            }));
        })
        .catch(error => {
            dispatch(addToast({
                type: 'warning',
                title: 'Unable to create PTR record',
                message: error.message
            }));
        });
};

const onNewAddressSubmit = ({setActiveModal}) => (values, {resetForm}) => {
    store.dispatch(createAddressThunk(buildAddressPayload(values)))
        .unwrap()
        .then(() => {
            if (values.createAddressRecord) createAddressRecord(store.dispatch, values);
            if (values.createReverseRecord) createReverseRecord(store.dispatch, values);
        })
        .then(() => {
            store.dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Address '${values.address}' has been created`
            }));
        })
        .catch(error => {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Unable to Create Address',
                message: error.message
            }));
        })
        .finally(() => {
            setActiveModal();
            resetForm();
        });
};

const formValidator = async ({
                                 networkAddress,
                                 addressFamily,
                                 hostname,
                                 defaultGateway,
                                 createReverseRecord,
                                 createAddressRecord
                             }) => {
    const errors = {};
    if (!networkAddress) {
        errors.networkAddress = 'Required';
    }

    if (addressFamily === 'IPv4') {
        if (!IPv4.isValidFourPartDecimal(networkAddress)) {
            errors.networkAddress = `Must be a valid IPv4 address`;
        }
        if (!defaultGateway === '' && !IPv4.isValidFourPartDecimal(defaultGateway)) {
            errors.defaultGateway = `Must be a valid IPv4 address`;
        }
    }
    if (addressFamily === 'IPv6') {
        if (!IPv6.isValid(networkAddress)) {
            errors.networkAddress = `Must be a valid IPv6 address`;
        }
        if (!defaultGateway === '' && !IPv6.isValid(defaultGateway)) {
            errors.defaultGateway = `Must be a valid IPv6 address`;
        }
    }

    if (hostname && !hostnameRegex.test(hostname)) {
        errors.hostname = `Must be a valid hostname`;
    }

    if (createReverseRecord && !selectReverseZone(networkAddress)) {
        try {
            let domainFetch = await ssoFetch(
                `${DNS_SERVICE}/domains?type=reverse&record=${getReverseRecord(networkAddress)}`,
                {method: 'GET'}
            );
            if (domainFetch.length < 1) {
                errors.createReverseRecord = 'Reverse zone not found';
                errors.hostname = 'Reverse zone not found';
            }
        } catch (err) {
            errors.createReverseRecord = 'Unable to fetch reverse zone';
            errors.hostname = 'Unable to fetch reverse zone';
        }
    }

    if (createAddressRecord && !selectDomainWithName(hostname)) {
        try {
            let domainFetch = await ssoFetch(
                `${DNS_SERVICE}/domains?record=${hostname}`,
                {method: 'GET'}
            );
            if (domainFetch.length < 1) {
                errors.createAddressRecord = 'Forward zone not found';
                errors.hostname = 'Forward zone not found';
            }
        } catch (err) {
            errors.createAddressRecord = 'Unable to fetch forward zone';
            errors.hostname = 'Unable to fetch forward zone';
        }
    }

    return errors;
};

const AddressCreator = ({pathItem, activeModal, setActiveModal}) => (
    <Formik validateOnBlur
            enableReinitialize
            initialValues={getInitialValues(pathItem)}
            validate={formValidator}
            onSubmit={onNewAddressSubmit({setActiveModal})}>
        {
            FormikModal({
                show: activeModal === 'new-address',
                onHide: () => setActiveModal(),
                onCancel: () => setActiveModal(),
                modalBody: AddressForm,
                modalHeader: () => <Modal.Title>New Address</Modal.Title>
            })
        }
    </Formik>
);

export default AddressCreator;