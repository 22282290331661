import {Container, Col, Row, Form, InputGroup} from "react-bootstrap";
import {SubnetInput, AddressFamilyInput, AddressInput, HostnameInput, DescriptionInput} from "../../inputs";
import React from "react";

const AddressForm = (props) => (
    <Container fluid>
        <Row className={"mb-3"}>
            <Col>
                <SubnetInput {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <AddressFamilyInput {...props} disabled={true}/>
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <AddressInput {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <DescriptionInput {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <HostnameInput {...props} />
            </Col>
        </Row>
        <Row>
            <Col>
                <InputGroup hasValidation>
                    <Form.Check>
                        <Form.Check.Input type={'checkbox'}
                                          key={'create-address-record'}
                                          disabled={props.values.hostname === ''}
                                          isValid={props.values.createAddressRecord && !props.errors.createAddressRecord}
                                          isInvalid={!!props.errors.createAddressRecord}
                                          checked={props.values.createAddressRecord}
                                          onChange={() => props.setFieldValue('createAddressRecord', !props.values.createAddressRecord)}/>
                        <Form.Check.Label key={'label'} className={"px-2"}>
                            {`Create '${props.values.addressFamily === 'IPv4' ? 'A' : 'AAAA'}' record`}
                        </Form.Check.Label>
                        <Form.Control.Feedback key={"feedback"} type="invalid">
                            {props.errors.createAddressRecord}
                        </Form.Control.Feedback>
                    </Form.Check>
                </InputGroup>
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <InputGroup hasValidation>
                    <Form.Check>
                        <Form.Check.Input type={'checkbox'}
                                          key={'createReverseCheckbox'}
                                          disabled={props.values.hostname === ''}
                                          isValid={props.values.createReverseRecord && !props.errors.createReverseRecord}
                                          isInvalid={!!props.errors.createReverseRecord}
                                          checked={props.values.createReverseRecord}
                                          onChange={() => props.setFieldValue('createReverseRecord', !props.values.createReverseRecord)}/>
                        <Form.Check.Label key={'label'} className={"px-2"}>
                            Create Reverse Record
                        </Form.Check.Label>
                        <Form.Control.Feedback key={"feedback"} type="invalid">
                            {props.errors.createReverseRecord}
                        </Form.Control.Feedback>
                    </Form.Check>
                </InputGroup>
            </Col>
        </Row>
    </Container>
);

export default AddressForm;