import {BsFillCaretDownFill, BsFillCaretUpFill} from "react-icons/bs";
import React from "react";

const renderSortIcon = (column) => (
    column.isSortedDesc ?
        <BsFillCaretDownFill style={{fontSize: '.75rem'}}/> :
        <BsFillCaretUpFill style={{fontSize: '.75rem'}}/>
);

const BsHeader = ({getColumnProps}) => (column) => (
    <th
        {...column.getHeaderProps([
            {style: column.style},
            getColumnProps(column),
            column.getSortByToggleProps()
        ])}>
        {column.render('Header')}
        <span>
            {column.isSorted && renderSortIcon(column)}
        </span>
    </th>
);

export default BsHeader;