import ipaddr from "ipaddr.js";

const pad = (part, size) => {
    let padded = part;
    while (padded.length < size) padded = "0" + padded;
    return padded;
}

const getNetmask = (cidr) => {
    let bitsLeft;
    return [0, 0, 0, 0].map(() => {
        bitsLeft = Math.min(cidr, 8);
        let octet = (256 - Math.pow(2, 8 - bitsLeft));
        cidr -= bitsLeft;
        return octet;
    });
};

const getIPv4ReverseZones = (address, cidr) => {
    if (ipaddr.isValid(address)) {
        let parsedAddress = ipaddr.parse(address);
        let netmask = getNetmask(cidr);

        let max = parsedAddress.octets.map((octet, idx) => octet + (255 - netmask[idx]));
        let arpa = ['arpa', 'in-addr'];
        let zones = [];

        max.forEach((max, idx) => {
            switch (netmask[idx]) {
                case 0: {
                    if (zones.length === 0) zones.push([...arpa]);
                    break;
                }
                case 255: {
                    arpa.push(parsedAddress.octets[idx]);
                    break;
                }
                default: {
                    if (cidr > 23) {
                        zones.push([...arpa]);
                    } else {
                        for (let i = parsedAddress.octets[idx]; i < max + 1; i++) {
                            zones.push([...arpa, i]);
                        }
                    }
                }
            }
        });

        return zones.map(zone => zone.reverse().join('.'));
    } else {
        return [];
    }
};

const getIPv4ReverseZone = (address, cidr) => {
    let parsedAddress = ipaddr.IPv4.parse(address);
    let netmask = getNetmask(cidr);

    return parsedAddress.octets
        .reduce((zone, octet, index) => {
            if (index < 3 && netmask[index] === 255) {
                return [...zone, octet];
            } else {
                return zone;
            }
        }, ['arpa', 'in-addr'])
        .reverse()
        .join('.');
};

const getReverseRecord = (address) => {
    if (ipaddr.isValid(address)) {
        if (ipaddr.parse(address).kind(address) === 'ipv4') {
            return getIPv4ReverseRecord(address);
        } else {
            return getIPv6ReverseRecord(address);
        }
    } else {
        return '';
    }
}

const getIPv4ReverseRecord = (address) => {
    let parsedAddress = ipaddr.IPv4.parse(address);

    return parsedAddress.octets
        .reduce((record, octet) => [...record, octet], ['arpa', 'in-addr'])
        .reverse()
        .join('.');
};

const getIPv6ReverseRecord = (address) => {
    let parsedAddress = ipaddr.IPv6.parse(address);

    return parsedAddress.parts
        .map(part => pad(part.toString(16), 4))
        .reduce((record, part) => [...record, ...part.split('')], ['arpa', 'ip6'])
        .reverse()
        .join('.');
};

const getIPv6ReverseZone = (address, cidr) => {
    if (ipaddr.isValid(address)) {
        let parsedAddress = ipaddr.IPv6.parse(address);

        let arpa = ['arpa', 'ip6'];
        let nearestNibble = Math.floor(cidr / 4);
        let currentNibble = 0;
        let parts = parsedAddress.parts.map(part => part.toString(16));

        parts.forEach(part => {
            let padded = pad(part, 4);
            for (let i = 0; i < 4; i++) {
                if (currentNibble < nearestNibble) {
                    arpa.push(padded[i]);
                }
                currentNibble += 1;
            }
        });

        return arpa.reverse().join('.');
    } else {
        return '';
    }
};

const getReverseZone = (address, cidr) => {
    if (ipaddr.isValid(address)) {
        if (ipaddr.parse(address).kind(address) === 'ipv4') {
            return getIPv4ReverseZone(address, cidr);
        } else {
            return getIPv6ReverseZone(address, cidr);
        }
    } else {
        return '';
    }
};

const getReverseZones = (address, cidr) => {
    if (ipaddr.isValid(address)) {
        if (ipaddr.parse(address).kind(address) === 'ipv4') {
            return getIPv4ReverseZones(address, cidr);
        } else {
            return [getIPv6ReverseZone(address, cidr)];
        }
    } else {
        return [];
    }
};

const getZones = (hostname) => {
    let domains = [];
    let parts = hostname.split('.');

    for (let idx = 1; idx < parts.length; idx++) {
        domains.push(parts.splice(idx).join('.'));
    }

    domains.push(hostname);

    return domains;
}

export {
    getZones,
    getNetmask,
    getReverseRecord,
    getIPv4ReverseRecord,
    getIPv6ReverseRecord,
    getReverseZone,
    getReverseZones,
    getIPv4ReverseZone,
    getIPv4ReverseZones,
    getIPv6ReverseZone
}