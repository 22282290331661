import {Form, FormControl, InputGroup} from "react-bootstrap";

const NameInput = ({
                       values, errors, handleChange,
                       name = 'name'
                   }) => (
    <InputGroup hasValidation>
        <InputGroup.Text>name</InputGroup.Text>
        <FormControl name={name}
                     onChange={handleChange}
                     value={values.name}
                     aria-label={"name"}
                     isValid={values.name && !errors.name}
                     isInvalid={!!errors.name}/>
        <Form.Control.Feedback type="invalid">
            {errors.name}
        </Form.Control.Feedback>
    </InputGroup>
);

export default NameInput;