import React from "react";
import {store} from "../../store";
import {Formik} from "formik";
import {addToast} from "../../store/toasts/toasts_slice";
import {FormikModal} from "../index";
import {Form, InputGroup, Modal} from "react-bootstrap";
import {popItem} from "../../store/path/path_slice";
import {deleteContextThunk, fetchContextsThunk} from "../../store/contexts/contexts_slice";


const getInitialValues = context => ({
    context: context,
    confirmDelete: false
});

const onSubmit = ({setActiveModal}) => (values) => {
    store.dispatch(deleteContextThunk(values.context))
        .unwrap()
        .then(() => {
            store.dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Context '${values.context.context_name}' has been deleted`
            }));
        })
        .catch(error => {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Unable to delete context',
                message: error.message
            }));
        })
        .finally(() => {
            store.dispatch(fetchContextsThunk());
            setActiveModal();
        });
};

const formValidator = values => {
    let errors = {};

    if (!values.confirmDelete) {
        errors.confirmDelete = 'Must be checked';
    }

    return errors;
};

const FormBody = props => {
    return (
        <React.Fragment>
            <p>{`Delete context '${props.values.context.context_name}' and all of its contents?`}</p>

            <InputGroup hasValidation>
                <Form.Check>
                    <Form.Check.Input type={'checkbox'}
                                      key={'confirmDelete'}
                                      isValid={props.values.confirmDelete}
                                      checked={props.values.confirmDelete}
                                      onChange={() => props.setFieldValue('confirmDelete', !props.values.confirmDelete)}/>
                    <Form.Check.Label key={'label'} className={"px-2"}>
                        Confirm
                    </Form.Check.Label>
                </Form.Check>
            </InputGroup>
        </React.Fragment>
    )
};

const ContextDeleteModal = ({context, activeModal, setActiveModal, callback}) => (
    <Formik validateOnBlur
            enableReinitialize
            initialValues={getInitialValues(context)}
            validate={formValidator}
            onSubmit={onSubmit({setActiveModal})}>
        {
            FormikModal({
                show: activeModal === 'delete-context',
                onHide: () => setActiveModal(),
                onCancel: () => setActiveModal(),
                modalBody: FormBody,
                modalHeader: () => <Modal.Title>Confirm Delete</Modal.Title>
            })
        }
    </Formik>
);

export default ContextDeleteModal;