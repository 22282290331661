import React from "react";
import {Card, Col, Dropdown, Row, Spinner} from "react-bootstrap";
import {BsDiagram3, BsThreeDotsVertical} from "react-icons/bs";
import {AddressTable} from "../../../components/tables";
import {selectAddressesWithSubnetId} from "../../../store/network/addresses/network_addresses_selector";
import {AddressCreator} from "../../../modals";
import SubnetEditor from "../../../modals/SubnetEditor/SubnetEditor";
import {connect} from "react-redux";
import SubnetDelete from "../../../modals/SubnetDelete/SubnetDelete";

const SubnetDetail = ({path, networkSubnets, networkAddresses, activeModal, setActiveModal}) => {
    let pathItem = path.path[path.path.length - 1];
    let subnet = networkSubnets.networkSubnets[pathItem.subnet_id];

    return (
        <React.Fragment>
            <Row className={'mb-3'}>
                <Col className={'col-lg-12'}>
                    <Card className={'shadow'}>
                        <Card.Header className={'bg-white'}>
                            <Dropdown className={'ms-2 float-end'}>
                                <Dropdown.Toggle id={"dropdown-basic"}
                                                 size={"sm"}
                                                 variant={"outline-light"}
                                                 className={'border-0 no-after'}
                                                 style={{
                                                     borderRadius: '15px'
                                                 }}>
                                    <BsThreeDotsVertical className={'text-black'} fontSize={'1rem'}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setActiveModal('edit-subnet')}>
                                        Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setActiveModal('delete-subnet')}>
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Card.Title>
                                <BsDiagram3/> {subnet ? subnet.network_address : ''}
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Subtitle>{subnet ? subnet.subnet_desc : ''}</Card.Subtitle>
                            <Card.Text>
                                <span className={'mb-1'}><b>Gateway</b><br/></span>
                                <span className={'ms-2 text-black-50'}>
                                    {subnet ? subnet.default_gateway : ''}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className={'col-lg-8'}>
                    <Card className={'shadow'}>
                        <Card.Header className={'bg-white'}>
                            {/*<Dropdown className={'ms-2 float-end'}>*/}
                            {/*    <Dropdown.Toggle id={"dropdown-basic"}*/}
                            {/*                     size={"sm"}*/}
                            {/*                     variant={"outline-light"}*/}
                            {/*                     className={'border-0 no-after'}*/}
                            {/*                     style={{*/}
                            {/*                         borderRadius: '15px'*/}
                            {/*                     }}>*/}
                            {/*        <BsThreeDotsVertical className={'text-black'} fontSize={'1rem'}/>*/}
                            {/*    </Dropdown.Toggle>*/}
                            {/*    <Dropdown.Menu>*/}
                            {/*        <Dropdown.Item onClick={() => setActiveModal('delete-address-item')}>*/}
                            {/*            Delete*/}
                            {/*        </Dropdown.Item>*/}
                            {/*    </Dropdown.Menu>*/}
                            {/*</Dropdown>*/}
                            <Card.Title>
                                IP Addresses
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {networkAddresses.status === 'pending' && (
                                <div className={'h-100'} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Spinner variant={'primary'} animation={'border'}/>
                                </div>
                            )}
                            {subnet && networkAddresses.status !== 'pending' && (
                                <AddressTable id={"address-table"}
                                              initialState={{globalFilter: ''}}
                                              data={selectAddressesWithSubnetId(networkAddresses, subnet.subnet_id)}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col className={'col-lg-4'}>
                    <Card className={'shadow'}>
                        <Card.Header className={'bg-white'}>
                            {/*<Dropdown className={'ms-2 float-end disabled'}>*/}
                            {/*    <Dropdown.Toggle id={"dropdown-basic"}*/}
                            {/*                     size={"sm"}*/}
                            {/*                     variant={"outline-light"}*/}
                            {/*                     className={'border-0 no-after float-end'}*/}
                            {/*                     style={{*/}
                            {/*                         borderRadius: '15px'*/}
                            {/*                     }}>*/}
                            {/*        <BsThreeDotsVertical className={'text-black'} fontSize={'1rem'}/>*/}
                            {/*    </Dropdown.Toggle>*/}
                            {/*    <Dropdown.Menu>*/}
                            {/*        <Dropdown.Item onClick={() => setActiveModal('delete-address-item')}>*/}
                            {/*            Delete*/}
                            {/*        </Dropdown.Item>*/}
                            {/*    </Dropdown.Menu>*/}
                            {/*</Dropdown>*/}
                            <Card.Title>
                                Properties
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Work in progress</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {subnet &&
                [
                    <AddressCreator
                        key={'address-creator-modal'}
                        pathItem={subnet}
                        activeModal={activeModal}
                        setActiveModal={setActiveModal}/>,
                    <SubnetEditor
                        key={'subnet-editor-modal'}
                        pathItem={subnet}
                        activeModal={activeModal}
                        setActiveModal={setActiveModal}/>,
                    <SubnetDelete
                        key={'subnet-delete-modal'}
                        pathItem={subnet}
                        activeModal={activeModal}
                        setActiveModal={setActiveModal}/>
                ]
            }
        </React.Fragment>
    )
};

const mapStateToProps = (state, ownProps) => ({
    path: state.path,
    networkSubnets: state.networkSubnets,
    networkAddresses: state.networkAddresses,
    activeModal: ownProps.activeModal,
    setActiveModal: ownProps.setActiveModal
});

export default connect(mapStateToProps)(SubnetDetail);