import React from "react";
import {store} from "../../../store";
import {Toast} from "react-bootstrap";
import {deleteToast} from "../../../store/toasts/toasts_slice";
import {BsFillCheckCircleFill} from "react-icons/bs";

const toastIcon = (type) => {
    switch (type) {
        case "notice": {
            return <BsFillCheckCircleFill size={'1.25rem'} className={'text-highlight'}/>
        }
        default: {
            return <BsFillCheckCircleFill size={'1.25rem'} color={'green'}/>
        }
    }
};

const SuccessToast = (toast) => (
    <Toast key={`toast-${toast.key}`}
           autohide delay={10000}
           onClose={() => store.dispatch(deleteToast(toast.key))}>
        <Toast.Header>
            {toastIcon(toast.type)}
            <strong className="m-auto">{toast.title}</strong>
        </Toast.Header>
        <Toast.Body>{toast.message}</Toast.Body>
    </Toast>
);

export default SuccessToast;