import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    path: []
};

const pathSlice = createSlice({
    name: 'pathSlice',
    initialState,
    reducers: {
        clearPath(state) {
            state.path = [];
        },
        pushScopeItem(state, action) {
            if (state.path.length > 0) {
                // Verify the last item in the path is a scope
                if (state.path[state.path.length - 1].itemType === 'scope') {
                    state.path = [...state.path, {...action.payload, itemType: 'scope'}];
                }
            } else {
                state.path = [...state.path, {...action.payload, itemType: 'scope'}];
            }
        },
        pushBlockItem(state, action) {
            if (state.path.length > 0) {
                if (state.path[state.path.length - 1].itemType === 'scope' ||
                    state.path[state.path.length - 1].itemType === 'block') {
                    state.path = [...state.path, {...action.payload, itemType: 'block'}];
                }
            } else {
                state.path = [...state.path, {...action.payload, itemType: 'block'}];
            }
        },
        pushSubnetItem(state, action) {
            if (state.path.length > 0) {
                if (state.path[state.path.length - 1].itemType === 'block') {
                    state.path = [...state.path, {...action.payload, itemType: 'subnet'}];
                }
            } else {
                state.path = [...state.path, {...action.payload, itemType: 'subnet'}];
            }
        },
        pushAddressItem(state, action) {
            if (state.path.length > 0) {
                if (state.path[state.path.length - 1].itemType === 'subnet') {
                    state.path = [...state.path, {...action.payload, itemType: 'address'}];
                }
            } else {
                state.path = [...state.path, {...action.payload, itemType: 'address'}];
            }
        },
        popItem(state) {
            state.path = [...state.path.slice(0, -1)];
        },
        gotoItem(state, action) {
            state.path = [...state.path.slice(0, action.payload)];
        },
        replaceItem(state, action) {
            state.path = [...state.path.slice(0, -1), action.payload];
        },
        updateItem(state, action) {
            let curItem = state.path.slice(-1);
            state.path = [...state.path.slice(0, -1), {...curItem[0], ...action.payload}];
        }
    }
});

export default pathSlice.reducer;
export const {
    pushScopeItem,
    pushSubnetItem,
    pushAddressItem,
    updateItem,
    popItem,
    gotoItem,
    clearPath,
    pushBlockItem,
    replaceItem
} = pathSlice.actions;