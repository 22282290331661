import React from "react";
import {store} from "../../store";
import {Formik} from "formik";
import {addToast} from "../../store/toasts/toasts_slice";
import {FormikModal} from "../index";
import {Modal} from "react-bootstrap";
import {fetchContextsThunk, updateContextThunk} from "../../store/contexts/contexts_slice";
import ContextForm from "../../components/forms/ContextForm/ContextForm";

const getInitialValues = ({context_name, context_desc}) => ({
    name: context_name || '',
    description: context_desc || ''
});

const buildContextPayload = (context, {name, description}) => ({
    context_id: context.context_id,
    context_name: name,
    context_desc: description
});

const onSubmit = ({context, setActiveModal}) => (values, {resetForm}) => {
    store.dispatch(updateContextThunk(buildContextPayload(context, values)))
        .unwrap()
        .then(() => {
            store.dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Context '${values.name}' has been updated`
            }));
        })
        .catch(error => {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Unable to update context',
                message: error.message
            }));
        })
        .finally(() => {
            store.dispatch(fetchContextsThunk());
            resetForm();
            setActiveModal();
        });
};

const formValidator = ({name, description}) => {
    const errors = {};

    if (!name) {
        errors.name = 'Required';
    }

    if (!description) {
        errors.description = 'Required';
    }

    return errors;
};

const ContextEditorModal = ({context, activeModal, setActiveModal}) => (
    <Formik validateOnBlur
            enableReinitialize
            initialValues={getInitialValues(context)}
            validate={formValidator}
            onSubmit={onSubmit({context, setActiveModal})}>
        {
            FormikModal({
                show: activeModal === 'edit-context',
                onHide: () => setActiveModal(),
                onCancel: () => setActiveModal(),
                modalBody: ContextForm,
                modalHeader: () => <Modal.Title>Edit Context</Modal.Title>
            })
        }
    </Formik>
);

export default ContextEditorModal;