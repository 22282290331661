import {FormControl, InputGroup} from "react-bootstrap";

const ParentBlockInput = ({values, handleChange}) => {
    return (
        <InputGroup>
            <InputGroup.Text>Parent Block</InputGroup.Text>
            <FormControl name={"parent_block"}
                         disabled
                         onChange={handleChange}
                         value={values.pathItem.network_address}
                         aria-label={"parent_block"}/>
        </InputGroup>
    )
};

export default ParentBlockInput;