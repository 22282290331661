import React from "react";
import {store} from "../../store";
import {Formik} from "formik";
import {addToast} from "../../store/toasts/toasts_slice";
import {FormikModal} from "../index";
import {Row, Col, Modal} from "react-bootstrap";
import {DescriptionInput, ScopeNameInput} from "../../components/inputs";
import {updateScopeThunk} from "../../store/scopes/scope_slice";
import {updateItem} from "../../store/path/path_slice";


const ScopeNameRegex = /^[A-Za-z0-9]*(_[A-Za-z0-9]*)*$/;

const getInitialValues = scope => ({
    scope: scope,
    name: scope.scope_name,
    label: scope.scope_label,
    description: scope.scope_desc
});

const buildScopePayload = ({scope, name, description}) => {
    return {
        scope_id: scope.scope_id,
        scope_name: name,
        scope_label: [...scope.scope_label.split('.').slice(0, -1), name].join('.'),
        scope_desc: description
    }
};

const onEditScopeSubmit = ({setActiveModal}) => (values, {resetForm}) => {
    store.dispatch(updateScopeThunk(buildScopePayload(values)))
        .unwrap()
        .then(() => {
            store.dispatch(updateItem({
                name: values.name,
                description: values.description
            }))
            store.dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Scope has been updated`
            }));
        })
        .catch(error => {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Unable to Update Scope',
                message: error.message
            }));
        })
        .finally(() => {
            resetForm();
            setActiveModal();
        });
};

const formValidator = ({name, description}) => {
    const errors = {};

    if (!name) {
        errors.name = 'Required';
    }

    if (!ScopeNameRegex.test(name)) {
        errors.name = 'Only alphanumeric characters and underscores allowed. A-Z a-z 0-9 _';
    }

    if (!description) {
        errors.description = 'Required';
    }

    return errors;
};

const FormBody = props => (
    <>
        <Row className={"mb-3"}>
            <Col>
                <ScopeNameInput {...props} valueKey={'name'}/>
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <DescriptionInput {...props} />
            </Col>
        </Row>
    </>
);

const ScopeEditorModal = ({scope, activeModal, setActiveModal}) => (
    <Formik validateOnBlur
            enableReinitialize
            initialValues={getInitialValues(scope)}
            validate={formValidator}
            onSubmit={onEditScopeSubmit({setActiveModal})}>
        {
            FormikModal({
                show: activeModal === 'edit-scope',
                onHide: () => setActiveModal(),
                onCancel: () => setActiveModal(),
                modalBody: FormBody,
                modalHeader: () => <Modal.Title>Edit Scope</Modal.Title>
            })
        }
    </Formik>
);

export default ScopeEditorModal;