import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {DNS_SERVICE} from "../../../api-server";
import ssoFetch from "../../../utils/sso-fetch";
import {buildQuery} from "../../../utils/url-utils";

const initialState = {
    status: 'idle',
    error: null,
    domains: {},
    nameMap: {}
};

export const fetchDomainsThunk = createAsyncThunk(
    'domains/fetchDomains',
    (args) => {
        return ssoFetch(
            `${DNS_SERVICE}/domains${buildQuery(args ? args : [])}`,
            {method: 'GET'}
        )}
);

export const createDomainThunk = createAsyncThunk(
    'domains/createDomains',
    (domain) => {
        return ssoFetch(
            `${DNS_SERVICE}/domains`,
            {
                method: 'POST',
                body: JSON.stringify(domain)
            }
        )}
);

const DomainSlice = createSlice({
    name: 'domains',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchDomainsThunk.pending]: (state) => {
            state.status = "pending";
        },
        [fetchDomainsThunk.fulfilled]: (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.domains = action.payload.reduce(
                    (obj, domain) => ({
                        ...obj,
                        [domain['id']]: domain
                    }),
                    state.domains
                );
                state.nameMap = action.payload.reduce(
                    (obj, domain) => ({
                        ...obj,
                        [domain['name']]: domain['id']
                    }),
                    state.nameMap
                )
            }

            state.status = "fulfilled";
        },
        [fetchDomainsThunk.rejected]: (state, action) => {
            state.error = action.payload;
            state.status = "rejected";
        }
    }
})

export default DomainSlice.reducer;