import React from 'react';
import {Container, Col, Row} from "react-bootstrap";
import {
    AddressBlockInput,
    AddressFamilyInput,
    DescriptionInput,
    ScopeInput
} from "../../inputs";

const AddressBlockForm = (props) => {console.log(props); return (
    <Container fluid>
        {props.scope && (
            <Row className={"mb-3"}>

                <Col>
                    <ScopeInput {...props} />
                </Col>
            </Row>
        )}
        <Row className={"mb-3"}>
            <Col>
                <AddressFamilyInput {...props} disabled={props.values.parent_block_id}/>
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <AddressBlockInput {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <DescriptionInput {...props} />
            </Col>
        </Row>

    </Container>
)};

export default AddressBlockForm;