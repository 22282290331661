import React from 'react';
import {BsTable} from '../../tables';
import {Button} from "react-bootstrap";
import {BsFillPencilFill, BsTrash} from "react-icons/bs";

const getColumnProps = (column) => {
    switch (column.Header) {
        case 'Address':
            return {style: {width: '25%'}};
        case 'Network Address':
            return {style: {width: '25%'}}
        default:
            return {};
    }
}

const AddressTable = ({data, addHandler, addDisabled, onDeleteRow, initialState}) => {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Address',
                accessor: 'address'
            },
            {
                Header: 'Hostname',
                accessor: 'hostname'
            },
            {
                Header: 'Description',
                accessor: 'address_desc'
            },
            // {
            //
            //     Header: () => null,
            //     id: 'expander',
            //     Cell: ({row}) => (
            //         <>
            //             <Button className={'ms-2 btn-circle btn-sm float-end border-0'}
            //                     variant={'outline-danger'}
            //                     onClick={() => {
            //                         console.log(row);
            //                     }}>
            //                 <BsTrash style={{fontSize: '.75rem'}}/>
            //             </Button>
            //             <Button className={'ms-2 btn-circle btn-sm float-end border-0'}
            //                     variant={'outline-primary'}
            //                     onClick={() => {
            //                         console.log(row);
            //                     }}>
            //                 <BsFillPencilFill style={{fontSize: '.75rem'}}/>
            //             </Button>
            //         </>
            //     )
            // }
        ], []
    );

    return <BsTable columns={columns}
                    data={data}
                    pagination={data.length > 0}
                    initialState={initialState}
                    getColumnProps={getColumnProps}
                    onDeleteRow={onDeleteRow}
                    addDisabled={addDisabled}
                    addHandler={addHandler}/>;
};

export default AddressTable;