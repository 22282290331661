import {Button, Modal} from "react-bootstrap";

const ConfirmActionModal = ({show, onCancel, onConfirm, message, title = 'Are you sure?'}) => (
    <Modal centered show={show}>
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>{message}</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="danger" onClick={onCancel}>Cancel</Button>
            <Button variant="primary" onClick={onConfirm}>Confirm</Button>
        </Modal.Footer>
    </Modal>
);

export default ConfirmActionModal;