import React from "react";
import {store} from "../../store";
import {Formik} from "formik";
import {IPv4, IPv6, parse} from "ipaddr.js";
import {addToast} from "../../store/toasts/toasts_slice";
import {createBlockThunk} from "../../store/network/blocks/network_blocks_slice";
import {AddressBlockForm, ReverseZoneForm} from "../../components/forms";
import {FormikModal} from "../index";
import {Modal} from "react-bootstrap";
import {getReverseRecord, getReverseZones} from "../../utils/zones/zones";
import {createDomainThunk} from "../../store/dns/domains/DomainSlice";
import {createRecordThunk} from "../../store/dns/records/RecordSlice";
import {deleteAddressThunk, fetchAddressesThunk} from "../../store/network/addresses/network_addresses_slice";


const getInitialValues = pathItem => {
    return {
        scope: pathItem.scope_name,
        scope_id: pathItem.scope_id,
        parent_block_id: pathItem.block_id,
        blockType: 'Subnet',
        addressFamily: `IPv${pathItem && pathItem.address_family ? pathItem.address_family : '4'}`,
        networkAddress: pathItem && pathItem.parent_block_id && pathItem.name ? pathItem.name.split('/')[0] : '',
        cidr: 24,
        description: '',
        createReverseZone: false,
        mname: 'ns1.acd.net.',
        rname: 'webmaster.acd.net.',
        refresh: 86400,
        retry: 7200,
        expire: 3600000,
        ttl: 86400
    }
};

const buildBlockPayload = ({scope_name, scope_id, parent_block_id, blockType, networkAddress, description, cidr}) => {
    return {
        scope_name: scope_name,
        scope_id: scope_id,
        parent_block_id: parent_block_id,
        block_type: blockType,
        network_address: `${parse(networkAddress).toString()}/${cidr}`,
        block_desc: description,
        properties: {
            "zoneValidationDisabled": false
        }
    }
};

const deleteAddressesHandler = ({
                                    dispatch,
                                    setShowConfirm,
                                    setConfirmMessage,
                                    setOnConfirm
                                }) => rows => {
    if (rows.length > 0) {
        setOnConfirm(() => () => // THUNK!
            rows.forEach(({original}) => {
                dispatch(deleteAddressThunk(original.address_id))
                    .unwrap()
                    .then(() => dispatch(fetchAddressesThunk({subnet_id: original.subnet_id})))
                    .then(() => {
                        dispatch(addToast({
                            type: 'success',
                            title: 'Success!',
                            message: `Address '${original.address}' has been deleted`
                        }));
                    })
                    .catch(error => {
                        dispatch(addToast({
                            type: 'warning',
                            title: 'Unable to Delete Address',
                            message: error.message
                        }));
                    })
                    .finally(() => setShowConfirm(false))
            })
        )
        setConfirmMessage(`Delete selected network addresses? Total: (${rows.length})`)
        setShowConfirm(true);
    }
};

const buildSOARecordPayload = (zone, {mname, rname, refresh, retry, expire, ttl}) => ({
    name: zone,
    type: 'SOA',
    content: `${mname} ${rname} 0 ${refresh} ${retry} ${expire} ${ttl}`,
    ttl: ttl,
    prio: 0,
    disabled: false,
    orderName: 0
});

const buildRecordPayload = ({
                                name,
                                content,
                                type = 'A',
                                ttl = 86400,
                                prio = 0,
                                disabled = false,
                                auth = 1
                            }) => ({name, content, type, ttl, prio, disabled, auth});

const createAddressRecord = (dispatch, hostname, address, addressFamily) => {
    let payload = buildRecordPayload({
        name: hostname,
        content: address,
        type: addressFamily === 'IPv6' ? 'AAAA' : 'A'
    });

    dispatch(createRecordThunk(payload))
        .unwrap()
        .then(() => {
            dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Address record for '${hostname}' has been created`
            }));
        })
        .catch(error => {
            dispatch(addToast({
                type: 'warning',
                title: 'Unable to Create A Record',
                message: error.message
            }));
        });
};

const createReverseRecord = (dispatch, domainId, hostname, address) => {
    let payload = buildRecordPayload({
        name: getReverseRecord(address),
        content: hostname,
        type: 'PTR'
    });

    dispatch(createRecordThunk(payload))
        .unwrap()
        .then(() => {
            dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Reverse record for '${address}' has been created`
            }));
        })
        .catch(error => {
            dispatch(addToast({
                type: 'warning',
                title: 'Unable to Create PTR Record',
                message: error.message
            }));
        });
};

const createSOARecord = (dispatch, zone, values) =>
    dispatch(createRecordThunk(buildSOARecordPayload(zone, values)))
        .unwrap()
        .catch(error => {
            dispatch(addToast({
                type: 'warning',
                title: 'Unable to Create SOA Record',
                message: error.message
            }));
        });

const createReverseZonesWithSOA = (dispatch, values) => {
    let reverseZones = getReverseZones(values.networkAddress, values.cidr);

    reverseZones.forEach(zone => {
        dispatch(createDomainThunk({name: zone}))
            .unwrap()
            .then(() => createSOARecord(dispatch, zone, values))
            .then(() => {
                dispatch(addToast({
                    type: 'success',
                    title: 'Success!',
                    message: `Reverse zone '${zone}' has been created`
                }));
            })
            .catch(error => {
                dispatch(addToast({
                    type: 'warning',
                    title: 'Unable to Create Zone',
                    message: error.message
                }));
            });
    });
};

const onNewBlockSubmit = ({setActiveModal}) => (values, {resetForm}) => {
    store.dispatch(createBlockThunk(buildBlockPayload(values)))
        .unwrap()
        .then(() => {
            if (values.createReverseZone) createReverseZonesWithSOA(store.dispatch, values);
        })
        .then(() => {
            store.dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Block '${values.networkAddress}/${values.cidr}' has been created`
            }));
        })
        .catch(error => {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Unable to Create Block',
                message: error.message
            }));
        })
        .finally(() => {
            setActiveModal();
            resetForm();
        });
};

const formValidator = ({networkAddress, addressFamily, cidr, createReverseZone}) => {
    const errors = {};
    if (!networkAddress) {
        errors.networkAddress = 'Required';
    }
    if (addressFamily === 'IPv4') {
        if (!IPv4.isValidFourPartDecimal(networkAddress)) {
            errors.networkAddress = `Must be a valid IPv4 address`;
        }
        if (cidr < 0 || cidr > 31) {
            errors.cidr = `Invalid CIDR`;
        }
        if (createReverseZone && cidr > 24) {
            errors.createReverseZone = `Delegation required for blocks with prefixes larger than 24-bits (RFC 2317)`
        }
    }
    if (addressFamily === 'IPv6') {
        if (!IPv6.isValid(networkAddress)) {
            errors.networkAddress = `Must be a valid IPv6 address`;
        }
        if (cidr < 0 || cidr > 127) {
            errors.cidr = `Invalid CIDR`;
        }
        if (createReverseZone && !(cidr % 4 === 0)) {
            errors.createReverseZone = `IPv6 reverse zone must exist on a nibble boundary (RFC 3596)`;
        }
    }

    return errors;
};

const FormBody = props => (
    <React.Fragment>
        <AddressBlockForm {...props} />
        <ReverseZoneForm {...props} />
    </React.Fragment>
);

const AddressBlockCreator = ({pathItem, activeModal, setActiveModal}) => (
    <Formik validateOnBlur
            enableReinitialize
            initialValues={getInitialValues(pathItem)}
            validate={formValidator}
            onSubmit={onNewBlockSubmit({setActiveModal})}>
        {
            FormikModal({
                show: activeModal === 'new-block',
                onHide: () => setActiveModal(),
                onCancel: () => setActiveModal(),
                modalBody: FormBody,
                modalHeader: () => <Modal.Title>Create Block</Modal.Title>
            })
        }
    </Formik>
);

export default AddressBlockCreator;