import React from "react";
import {store} from "../../store";
import {Formik} from "formik";
import {IPv4, IPv6, parse} from "ipaddr.js";
import {addToast} from "../../store/toasts/toasts_slice";
import {SubnetForm} from "../../components/forms";
import {FormikModal} from "../index";
import {Modal} from "react-bootstrap";
import {createSubnetThunk} from "../../store/network/subnets/network_subnets_slice";
import {createRecordThunk} from "../../store/dns/records/RecordSlice";
import {getReverseZones} from "../../utils/zones/zones";
import {createDomainThunk} from "../../store/dns/domains/DomainSlice";


const getInitialValues = pathItem => {return {
    subnet_id: null,
    pathItem: pathItem,
    addressFamily: `IPv${pathItem && pathItem.address_family ? pathItem.address_family : '4'}`,
    networkAddress: pathItem && pathItem.name ? pathItem.name.split('/')[0] : '',
    cidr: pathItem && pathItem.name ? pathItem.name.split('/')[1] : '24',
    defaultGateway: '',
    description: '',
    createReverseZone: false,
    mname: 'ns1.acd.net.',
    rname: 'webmaster.acd.net.',
    refresh: 86400,
    retry: 7200,
    expire: 3600000,
    ttl: 86400
}};

const buildSubnetPayload = ({pathItem, networkAddress, description, cidr, defaultGateway}) => {
    return {
        block_id: pathItem.block_id,
        network_address: `${parse(networkAddress).toString()}/${cidr}`,
        subnet_desc: description,
        default_gateway: defaultGateway ? defaultGateway : null
    };
};
const buildSOARecordPayload = (zone, {mname, rname, refresh, retry, expire, ttl}) => ({
    name: zone,
    type: 'SOA',
    content: `${mname} ${rname} 0 ${refresh} ${retry} ${expire} ${ttl}`,
    ttl: ttl,
    prio: 0,
    disabled: false,
    orderName: 0
});

const buildRecordPayload = ({
                                name,
                                content,
                                type = 'A',
                                ttl = 86400,
                                prio = 0,
                                disabled = false,
                                auth = 1
                            }) => ({name, content, type, ttl, prio, disabled, auth});

const createSOARecord = (dispatch, zone, values) =>
    dispatch(createRecordThunk(buildSOARecordPayload(zone, values)))
        .unwrap()
        .catch(error => {
            dispatch(addToast({
                type: 'warning',
                title: 'Unable to Create SOA Record',
                message: error.message
            }));
        });

const createReverseZonesWithSOA = (dispatch, values) => {
    let reverseZones = getReverseZones(values.networkAddress, values.cidr);

    reverseZones.forEach(zone => {
        dispatch(createDomainThunk({name: zone}))
            .unwrap()
            .then(() => createSOARecord(dispatch, zone, values))
            .then(() => {
                dispatch(addToast({
                    type: 'success',
                    title: 'Success!',
                    message: `Reverse zone '${zone}' has been created`
                }));
            })
            .catch(error => {
                dispatch(addToast({
                    type: 'warning',
                    title: 'Unable to Create Zone',
                    message: error.message
                }));
            });
    });
};

const onNewSubnetSubmit = ({setActiveModal}) => (values, {resetForm}) => {
    store.dispatch(createSubnetThunk(buildSubnetPayload(values)))
        .unwrap()
        .then(() => {
            if (values.createReverseZone) createReverseZonesWithSOA(store.dispatch, values);
        })
        .then(() => {
            store.dispatch(addToast({
                type: 'success',
                title: 'Success!',
                message: `Subnet has been created`
            }));
        })
        .catch(error => {
            store.dispatch(addToast({
                type: 'warning',
                title: 'Unable to Create Subnet',
                message: error.message
            }));
        })
        .finally(() => {
            setActiveModal();
            resetForm();
        });
};

const formValidator = ({networkAddress, addressFamily, defaultGateway, createReverseZone, cidr}) => {
    const errors = {};
    if (!networkAddress) {
        errors.networkAddress = 'Required';
    }

    if (addressFamily === 'IPv4') {
        if (!IPv4.isValidFourPartDecimal(networkAddress)) {
            errors.networkAddress = `Must be a valid IPv4 address`;
        }
        if (cidr < 0 || cidr > 31) {
            errors.cidr = `Invalid CIDR`;
        }
        if (!defaultGateway === '' && !IPv4.isValidFourPartDecimal(defaultGateway)) {
            errors.defaultGateway = `Must be a valid IPv4 address`;
        }
        if (createReverseZone && cidr > 24) {
            errors.createReverseZone = `Delegation required for blocks with prefixes larger than 24-bits (RFC 2317)`
        }
    }

    if (addressFamily === 'IPv6') {
        if (!IPv6.isValid(networkAddress)) {
            errors.networkAddress = `Must be a valid IPv6 address`;
        }
        if (cidr < 0 || cidr > 127) {
            errors.cidr = `Invalid CIDR`;
        }
        if (!(IPv6.isValid(defaultGateway) || defaultGateway === '')) {
            errors.defaultGateway = `Must be a valid IPv6 address`;
        }
        if (createReverseZone && !(cidr % 4 === 0)) {
            errors.createReverseZone = `IPv6 reverse zone must exist on a nibble boundary (RFC 3596)`
        }
    }

    return errors;
};

const SubnetCreator = ({pathItem, activeModal, setActiveModal}) => (
    <Formik validateOnBlur
            enableReinitialize
            initialValues={getInitialValues(pathItem)}
            validate={formValidator}
            onSubmit={onNewSubnetSubmit({setActiveModal})}>
        {
            FormikModal({
                show: activeModal === 'new-subnet',
                onHide: () => setActiveModal(),
                onCancel: () => setActiveModal(),
                modalBody: SubnetForm,
                modalHeader: () => <Modal.Title>New Subnet</Modal.Title>
            })
        }
    </Formik>
);

export default SubnetCreator;