import {Container, Col, Row} from "react-bootstrap";
import {DescriptionInput, NameInput} from "../../inputs";

const ContextForm = (props) => (
    <Container fluid>
        <Row className={"mb-3"}>
            <Col>
                <NameInput {...props} />
            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col>
                <DescriptionInput {...props} />
            </Col>
        </Row>
    </Container>
);

export default ContextForm;