const queryParam = (key, value) => `${key}=${value}`;
const queryString = ({key, value}, i) => i === 0 ? `?${queryParam(key, value)}` : queryParam(key, value);

export const buildQuery = (args) => {
    let queryParams = [];
    Object.keys(args).forEach(key => {
        let value = args[key];
        if (typeof value === Array) {
            value.map(item => queryParams.push({key, value: item}));
        } else {
            queryParams.push({key, value});
        }
    });

    return queryParams.map((param, i) => queryString(param, i)).join('&');
};