import BlockContextMenu from "./BlockContextMenu";
import RootContextMenu from "./RootContextMenu";
import ScopeContextMenu from "./ScopeContextMenu";
import SubnetContextMenu from "./SubnetContextMenu";

const ContextMenu = (props) => {
    if (props.showMenu) {
        if (!props.pathItem) return RootContextMenu(props);

        switch(props.pathItem.itemType) {
            case 'scope': {
                return ScopeContextMenu(props);
            }
            case 'block': {
                return BlockContextMenu(props);
            }
            case 'subnet': {
                return SubnetContextMenu(props);
            }
            case 'address' : {
                return BlockContextMenu(props);
            }
            default: {
                return null;
            }
        }
    } else {
        return null;
    }
}

export default ContextMenu;