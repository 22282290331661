import React from "react";
import {Card, Col, Dropdown, Row} from "react-bootstrap";
import {BsBox, BsThreeDotsVertical} from "react-icons/bs";
import AddressBlockCreator from "../../../modals/AddressBlockCreator/AddressBlockCreator";
import SubnetCreator from "../../../modals/SubnetCreator/SubnetCreator";
import {AddressBlockEditor} from "../../../modals";
import AddressBlockDelete from "../../../modals/AddressBlockDelete/AddressBlockDelete";

const BlockDetail = ({block, activeModal, setActiveModal}) => {
    return (
        <React.Fragment>
            <Row className={'mb-3'}>
                <Col className={'col-lg-12'}>
                    <Card className={'shadow'}>
                        <Card.Header className={'bg-white'}>
                            <Dropdown className={'ms-2 float-end'}>
                                <Dropdown.Toggle id={"dropdown-basic"}
                                                 size={"sm"}
                                                 variant={"outline-light"}
                                                 className={'border-0 no-after'}
                                                 style={{
                                                     borderRadius: '15px'
                                                 }}>
                                    <BsThreeDotsVertical className={'text-black'} fontSize={'1rem'}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setActiveModal('edit-block')}>
                                        Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setActiveModal('delete-block')}>
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Card.Title>
                                <BsBox/> {block ? block.network_address : ''}
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Subtitle>{block ? block.block_desc : ''}</Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className={'col-lg-4'}>
                    <Card className={'shadow'}>
                        <Card.Header className={'bg-white'}>
                            {/*<Dropdown className={'ms-2 float-end'}>*/}
                            {/*    <Dropdown.Toggle id={"dropdown-basic"}*/}
                            {/*                     size={"sm"}*/}
                            {/*                     variant={"outline-light"}*/}
                            {/*                     className={'border-0 no-after float-end'}*/}
                            {/*                     style={{*/}
                            {/*                         borderRadius: '15px'*/}
                            {/*                     }}>*/}
                            {/*        <BsThreeDotsVertical className={'text-black'} fontSize={'1rem'}/>*/}
                            {/*    </Dropdown.Toggle>*/}
                            {/*    <Dropdown.Menu>*/}
                            {/*        <Dropdown.Item onClick={() => setActiveModal('add-property')}>*/}
                            {/*            Add*/}
                            {/*        </Dropdown.Item>*/}
                            {/*    </Dropdown.Menu>*/}
                            {/*</Dropdown>*/}
                            <Card.Title>
                                Properties
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Work in progress</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {block &&
                [
                    <AddressBlockCreator
                        key={'block-creator-modal'}
                        pathItem={block}
                        activeModal={activeModal}
                        setActiveModal={setActiveModal}/>,
                    <AddressBlockEditor
                        key={'block-editor-modal'}
                        pathItem={block}
                        activeModal={activeModal}
                        setActiveModal={setActiveModal}/>,
                    <AddressBlockDelete
                        key={'block-delete-modal'}
                        pathItem={block}
                        activeModal={activeModal}
                        setActiveModal={setActiveModal}/>,
                    <SubnetCreator
                        key={'subnet-creator-modal'}
                        pathItem={block}
                        activeModal={activeModal}
                        setActiveModal={setActiveModal}/>
                ]
            }
        </React.Fragment>
    )
};

export default BlockDetail;