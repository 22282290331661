import {BsBox, BsDiagram2} from "react-icons/bs";
import BasicContextMenu from "./BasicContextMenu";

const onMenuItemClick = (key, props) => () => {
    props.setShowMenu(false);
    props.setActiveModal(key);
};

const menuItems = props => ([
    {
        key: 'new-block-item',
        title: 'New Block',
        icon: <BsBox color={'green'}/>,
        onClick: onMenuItemClick('new-block', props),
        allowedTypes: ['block4', 'block6', 'scope']
    },
    {
        key: 'new-subnet-item',
        title: 'New Subnet',
        icon: <BsDiagram2 color={'green'}/>,
        onClick: onMenuItemClick('new-subnet', props),
        allowedTypes: ['block4', 'block6']
    }
]);

const BlockContextMenu = (props) => {
    if (props.showMenu) {
        return BasicContextMenu({
            ...props,
            menuItems: menuItems(props)
        });
    } else {
        return null;
    }
};

export default BlockContextMenu;