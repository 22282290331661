import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {DNS_SERVICE} from "../../../api-server";
import ssoFetch from "../../../utils/sso-fetch";
import {buildQuery} from "../../../utils/url-utils";

const initialState = {
    status: 'idle',
    error: null,
    domains: {}
};

export const fetchRecordsThunk = createAsyncThunk(
    'records/fetchRecords',
    (args) => {
        return ssoFetch(
            `${DNS_SERVICE}/records${buildQuery(args ? args : [])}`,
            {method: 'GET'}
        )}
);

export const createRecordThunk = createAsyncThunk(
    'records/createRecords',
    (record) => {
        return ssoFetch(
            `${DNS_SERVICE}/records`,
            {
                method: 'POST',
                body: JSON.stringify(record)
            }
        )}
);

const DomainSlice = createSlice({
    name: 'records',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchRecordsThunk.pending]: (state) => {
            state.status = "pending";
        },
        [fetchRecordsThunk.fulfilled]: (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.records = action.payload.reduce(
                    (obj, record) => ({
                        ...obj,
                        [record['id']]: record
                    }),
                    state.records
                );
            };

            state.status = "fulfilled";
        },
        [fetchRecordsThunk.rejected]: (state, action) => {
            state.error = action.payload;
            state.status = "rejected";
        }
    }
})

export default DomainSlice.reducer;