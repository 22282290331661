import {configureStore} from "@reduxjs/toolkit";
import NetworkBlockReducer from "./network/blocks/network_blocks_slice";
import NetworkSubnetReducer from './network/subnets/network_subnets_slice';
import NetworkAddressReducer from './network/addresses/network_addresses_slice';
import ScopeSlice from './scopes/scope_slice';
import ToastSlice from './toasts/toasts_slice';
import ContextSlice from './contexts/contexts_slice';
import DomainSlice from "./dns/domains/DomainSlice";
import PathSlice from './path/path_slice';

export const store = configureStore({
    reducer: {
        path: PathSlice,
        contexts: ContextSlice,
        scopes: ScopeSlice,
        toasts: ToastSlice,
        domains: DomainSlice,
        networkBlocks: NetworkBlockReducer,
        networkSubnets: NetworkSubnetReducer,
        networkAddresses: NetworkAddressReducer
    },
});

export default store;