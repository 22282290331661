import {Form, InputGroup} from "react-bootstrap";

const AddressFamilyInput = ({values, handleChange, disabled = false}) => (
    <InputGroup>
        <InputGroup.Text>Address Family</InputGroup.Text>
        <Form.Select disabled={disabled}
                     name={"addressFamily"}
                     value={values.addressFamily}
                     onChange={handleChange}>
            <option value="IPv4">IPv4</option>
            <option value="IPv6">IPv6</option>
        </Form.Select>
    </InputGroup>
);

export default AddressFamilyInput;