import {Form, FormControl, InputGroup} from "react-bootstrap";

const DescriptionInput = ({
                              values, errors, handleChange,
                              name = 'description'
                          }) => (
    <InputGroup hasValidation>
        <InputGroup.Text>Description</InputGroup.Text>
        <FormControl name={name}
                     onChange={handleChange}
                     value={values.description}
                     aria-label={"description"}
                     isValid={values.description && !errors.description}
                     isInvalid={!!errors.description}/>
        <Form.Control.Feedback type="invalid">
            {errors.description}
        </Form.Control.Feedback>
    </InputGroup>
);

export default DescriptionInput;