import React from "react";
import {Card, Col, Dropdown, Row} from "react-bootstrap";
import {BsLayers, BsThreeDotsVertical} from "react-icons/bs";
import ContextEditorModal from "../../../modals/ContextEditorModal/ContextEditorModal";
import ContextDeleteModal from "../../../modals/ContextDeleteModal/ContextDeleteModal";
import ScopeCreator from "../../../modals/ScopeCreator/ScopeCreator";

const ContextDetail = ({context, activeModal, setActiveModal}) => (
    <React.Fragment>
        <Row className={'mb-3'}>
            <Col className={'col-lg-12'}>
                <Card className={'shadow'}>
                    <Card.Header className={'bg-white'}>
                        <Dropdown className={'ms-2 float-end'}>
                            <Dropdown.Toggle id={"dropdown-basic"}
                                             size={"sm"}
                                             variant={"outline-light"}
                                             className={'border-0 no-after float-end'}
                                             style={{
                                                 borderRadius: '15px'
                                             }}>
                                <BsThreeDotsVertical className={'text-black'} fontSize={'1rem'}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setActiveModal('edit-context')}>
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setActiveModal('delete-context')}>
                                    Delete
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Card.Title>
                            <BsLayers/> {context ? context.context_name : ''}
                        </Card.Title>
                        <Card.Subtitle>{context ? context.context_desc : ''}</Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {context &&
            [
                <ScopeCreator key={'scope-creator-modal'}
                              activeModal={activeModal}
                              setActiveModal={setActiveModal}/>,
                <ContextDeleteModal key={'delete-context-modal'}
                                    context={context}
                                    activeModal={activeModal}
                                    setActiveModal={setActiveModal}/>,
                <ContextEditorModal key={'edit-context-modal'}
                                    context={context}
                                    activeModal={activeModal}
                                    setActiveModal={setActiveModal}/>
            ]
        }

    </React.Fragment>
);

export default ContextDetail;