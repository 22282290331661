import {Form, InputGroup} from "react-bootstrap";

const StringInput = ({
                         values,
                         errors,
                         text,
                         handleChange,
                         name,
                         disabled = false,
                         hasValidation = false,
                         validFeedback
                     }) => (
    <InputGroup hasValidation={hasValidation}>
        <InputGroup.Text>{text}</InputGroup.Text>
        <Form.Control
            key={name}
            name={name}
            disabled={disabled}
            value={values[name]}
            type={'string'}
            isValid={values[name] && !errors[name]}
            isInvalid={!!errors[name]}
            onChange={handleChange}/>

        <Form.Control.Feedback key={'valid-feedback'} type={'valid'}>
            {validFeedback}
        </Form.Control.Feedback>
        <Form.Control.Feedback key={'invalid-feedback'} type={'invalid'}>
            {errors[name]}
        </Form.Control.Feedback>
    </InputGroup>
);

export default StringInput;