import React, {useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {Col, Container, Row, Spinner, ToastContainer} from "react-bootstrap";
import {connect} from "react-redux";
import {ErrorToast} from "./components/toasts";
import SuccessToast from "./components/toasts/SuccessToast/SuccessToast";
import {ConfirmActionModal} from "./modals";
import HierarchyView from "./views/HierarchyView/HierarchyView";
import PathView from "./views/PathView/PathView";
import ContextSelector from "./views/ContextSelector/ContextSelector";


const App = (props) => {
    const [path, setPath] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmMessage] = useState('Are you sure?');
    const [onConfirm] = useState(() => {
    });

    const {keycloak} = useKeycloak();

    if (!keycloak.authenticated) return (
        <div className={'h-100'} style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Spinner variant={'primary'} animation={'border'}/>
        </div>
    );

    return (
        <Container id={"ipam-app"} className={"h-100"} fluid>
            <Row id={"ipam-hierarchy-view-toolbar"}
                 className={"bg-primary"}>
                <Col className={'col-2'}>
                    <ContextSelector/>
                </Col>
                <Col className={'col-10'}>
                    <PathView contexts={props.contexts} path={path} setPath={setPath}/>
                </Col>
            </Row>
            <Row id={"ipam-hierarchy-view"}>
                <HierarchyView path={path} setPath={setPath}/>
            </Row>

            <ConfirmActionModal key={"confirm-modal"}
                                show={showConfirm}
                                message={confirmMessage}
                                onCancel={() => setShowConfirm(false)}
                                onConfirm={onConfirm}/>
            <ToastContainer position="bottom-end" className="p-3 position-absolute">
                {Object.values(props.toasts).map(toast => {
                    if (toast.type === 'warning' || toast.type === 'error') {
                        return ErrorToast(toast);
                    } else {
                        return SuccessToast(toast);
                    }
                })}
            </ToastContainer>
        </Container>
    )
};

const mapStateToProps = (state) => {
    return {
        toasts: state.toasts.toastObjects,
        contexts: state.contexts
    }
};

export default connect(mapStateToProps)(App)