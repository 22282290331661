import React, {useEffect, useState} from "react";
import {Col, Row, ListGroup, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import {fetchScopesThunk} from "../../store/scopes";
import {
    selectNetworkBlocksWithParent,
    selectNetworkBlocksWithScope
} from "../../store/network/blocks/network_blocks_selectors";
import {selectSubnetsWithBlockId} from "../../store/network/subnets/SubnetSelectors";
import {selectAddressesWithSubnetId} from "../../store/network/addresses/network_addresses_selector";
import {store} from "../../store";
import {selectScopesWithRoot} from "../../store/scopes/ScopeSelectors";
import {BsBox, BsCaretLeft, BsCaretRight, BsDiagram3, BsDot, BsFolder} from "react-icons/bs";
import {
    popItem,
    pushAddressItem,
    pushBlockItem,
    pushScopeItem,
    pushSubnetItem
} from "../../store/path/path_slice";
import {fetchBlocksThunk} from "../../store/network/blocks/network_blocks_slice";
import ContextMenu from "../ContextMenu/ContextMenu";
import {fetchSubnetsThunk} from "../../store/network/subnets/network_subnets_slice";
import {isFulfilled} from "../../store/contexts/contexts_selectors";
import {fetchContextsThunk} from "../../store/contexts/contexts_slice";
import {fetchAddressesThunk} from "../../store/network/addresses/network_addresses_slice";
import ContextDetail from "./ContextDetail/ContextDetail";
import ScopeDetail from "./ScopeDetail/ScopeDetail";
import BlockDetail from "./BlockDetail/BlockDetail";
import SubnetDetail from "./SubnetDetail/SubnetDetail";

const ScopeItem = (scope) => (
    <ListGroup.Item action
                    key={`scope_${scope.scope_label}`}
                    className={'p-1'}
                    onClick={() => {
                        store.dispatch(pushScopeItem(scope))
                    }}>
        <div className={'d-inline float-start pe-1'}>
            <BsFolder/>
        </div>
        <div className={'d-inline float-start text-truncate'} style={{maxWidth: '70%'}}>
            <span className={'me-2'}>{scope.scope_name}</span>
        </div>
        <div className={'d-inline float-end'}>
            <BsCaretRight/>
        </div>

    </ListGroup.Item>
);

const BlockItem = (block) => {
    return (
        <ListGroup.Item key={`block_${block.block_id}`}
                        className={'p-1'}
                        action onClick={() => {
            store.dispatch(pushBlockItem(block))
        }}>
            <div className={'d-inline float-start pe-1'}>
                <BsBox/>
            </div>
            <div className={'d-inline float-start text-truncate'} style={{maxWidth: '70%'}}>
                <span className={'me-2'}>{block.network_address}</span>
            </div>
            <div className={'d-inline float-end'}>
                <BsCaretRight/>
            </div>
        </ListGroup.Item>
    )
};

const SubnetItem = (subnet) => {
    return (
        <ListGroup.Item key={`subnet_${subnet.subnet_id}`}
                        action
                        className={'p-1'}
                        onClick={() => {
                            store.dispatch(pushSubnetItem(subnet))
                        }}>
            <div className={'d-inline float-start pe-1'}>
                <BsDiagram3/>
            </div>
            <div className={'d-inline float-start text-truncate'} style={{maxWidth: '70%'}}>
                <span className={'me-2'}>{subnet.network_address}</span>
            </div>
            <div className={'d-inline float-end'}>
                <BsCaretRight/>
            </div>
        </ListGroup.Item>
    )
};

const AddressItem = (address) => {
    return (
        <ListGroup.Item key={`address_${address.address_id}`}
                        action
                        className={'p-1'}
                        onClick={() => {
                            store.dispatch(pushAddressItem(address))
                        }}>
            <div className={'d-inline float-start pe-1'}>
                <BsDot/>
            </div>
            <div className={'d-inline float-start text-truncate'} style={{maxWidth: '70%'}}>
                <span className={'me-2'}>{address.address}</span>
            </div>
            <div className={'d-inline float-end'}>
                <BsCaretRight/>
            </div>
        </ListGroup.Item>
    )
};

const BackItem = () => {
    return (
        <ListGroup.Item key={`back`}
                        action
                        className={'p-1'}
                        onClick={() => {
                            store.dispatch(popItem())
                        }}>
            <div className={'d-inline float-start pe-1'}>
                <BsCaretLeft/> <span style={{fontSize: '.75rem'}}>Back</span>
            </div>
        </ListGroup.Item>
    )
};

const fetchBlocks = (currentContext, pathItem) => {
    if (currentContext) {
        if (pathItem && pathItem.itemType === 'scope') {
            store.dispatch(fetchBlocksThunk({
                scope_id: pathItem.scope_id
            }));
        } else if (pathItem && pathItem.itemType === 'block') {
            store.dispatch(fetchBlocksThunk({
                parent_id: pathItem.block_id
            }));
        }
    }
};

const fetchSubnets = (currentContext, pathItem) => {
    if (currentContext) {
        if (pathItem && pathItem.itemType === 'block') {
            store.dispatch(fetchSubnetsThunk({
                block_id: pathItem.block_id
            }));
        }
    }
};

const fetchAddresses = (currentContext, pathItem) => {
    if (currentContext) {
        if (pathItem && pathItem.itemType === 'subnet') {
            store.dispatch(fetchAddressesThunk({
                subnet_id: pathItem.subnet_id
            }));
        }
    }
};

const getDetailComponent = (item, activeModal, setActiveModal, {contexts, networkAddresses}) => {
    if (!item) {
        return ContextDetail({
            context: contexts.currentContext,
            activeModal,
            setActiveModal
        });
    }

    switch (item.itemType) {
        case 'scope': {
            return ScopeDetail({
                activeModal,
                setActiveModal,
                scope: item
            });
        }
        case 'block': {
            return BlockDetail({
                activeModal,
                setActiveModal,
                block: item
            });
        }
        case 'subnet': {
            return (
                <SubnetDetail key={'subnet-detail'}
                                  activeModal={activeModal}
                                  setActiveModal={setActiveModal}/>
            );
        }
        default: {
            return [];
        }
    }
}

const HierarchyView = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    const [positionY, setPositionY] = useState(0);
    const [positionX, setPositionX] = useState(0);
    const [activeModal, setActiveModal] = useState('');
    let pathItem = props.path.path[props.path.path.length - 1];

    useEffect(() => {
        if (!isFulfilled(store.getState())) {
            store.dispatch(fetchContextsThunk());
        }
    }, []);

    useEffect(() => {
        const abortCtrl = new AbortController();
        const opts = {signal: abortCtrl.signal};

        if (props.contexts.currentContext) {
            if (pathItem && pathItem.itemType === 'scope') {
                store.dispatch(fetchScopesThunk({
                    query: {
                        context_id: props.contexts.currentContext['context_id'],
                        root: pathItem.scope_label,
                        depth: 1
                    },
                    options: opts
                }));
            } else {
                store.dispatch(fetchScopesThunk({
                    query: {
                        context_id: props.contexts.currentContext['context_id'],
                        depth: 1
                    },
                    options: opts
                }));
            }
        }

        return () => abortCtrl.abort();
    }, [
        props.path.path,
        props.contexts.currentContext,
        props.scopes.lastUpdated
    ]);

    useEffect(() => {
        fetchBlocks(props.contexts.currentContext, pathItem);
    }, [
        pathItem,
        props.contexts.currentContext,
        props.networkBlocks.lastUpdated
    ]);

    useEffect(() => {
        fetchSubnets(props.contexts.currentContext, pathItem);
    }, [
        pathItem,
        props.contexts.currentContext,
        props.networkSubnets.lastUpdated
    ]);

    useEffect(() => {
        fetchAddresses(props.contexts.currentContext, pathItem);
    }, [
        pathItem,
        props.contexts.currentContext,
        props.networkAddresses.lastUpdated
    ]);

    const handleContextMenu = (event) => {
        setShowMenu(true);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        event.preventDefault();
    };

    if (props.scopes.status === 'pending' || props.networkBlocks.status === 'pending') {
        return (
            <div className={'h-100'} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Spinner variant={'primary'} animation={'border'}/>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Col className={'border-end col-lg-2 h-100 mh-100'}>
                <Row id={"ipam-hierarchy-view"} className={'h-100 mh-100'}
                     style={{overflowX: 'hidden', overflowY: 'auto'}}>
                    <Col className={'m-0 p-0'} onContextMenu={handleContextMenu}>
                        {props.contexts.currentContext && (
                            <ListGroup className={'mx-auto'} variant={"flush"}>
                                {(!pathItem || pathItem.itemType === "scope") &&
                                    selectScopesWithRoot(
                                        props.scopes,
                                        props.contexts.currentContext.context_id,
                                        pathItem
                                    ).map(ScopeItem)
                                }
                                {pathItem && pathItem.itemType === 'scope' &&
                                    selectNetworkBlocksWithScope(pathItem)
                                        .sort((a, b) => (a.network_address > b.network_address) ? 1 : -1)
                                        .map(BlockItem)
                                }
                                {pathItem && pathItem.itemType === 'block' &&
                                    selectNetworkBlocksWithParent(pathItem)
                                        .sort((a, b) => (a.network_address > b.network_address) ? 1 : -1)
                                        .map(BlockItem)
                                }
                                {pathItem && pathItem.itemType === 'block' &&
                                    selectSubnetsWithBlockId(props.networkSubnets, pathItem.block_id)
                                        .sort((a, b) => (a.network_address > b.network_address) ? 1 : -1)
                                        .map(SubnetItem)
                                }
                                {pathItem && pathItem.itemType === 'subnet' &&
                                    selectAddressesWithSubnetId(props.networkAddresses, pathItem.subnet_id)
                                        .sort((a, b) => (a.network_address > b.network_address) ? 1 : -1)
                                        .map(AddressItem)
                                }
                                {pathItem && BackItem()}
                            </ListGroup>
                        )}
                    </Col>
                </Row>
                <ContextMenu xPos={positionX}
                             yPos={positionY}
                             pathItem={pathItem}
                             showMenu={showMenu}
                             setShowMenu={setShowMenu}
                             activeModal={activeModal}
                             setActiveModal={setActiveModal}/>
            </Col>
            <Col className={'border-start col-lg-10 p-3 bg-light'}>
                {getDetailComponent(
                    pathItem,
                    activeModal,
                    setActiveModal,
                    props
                )}
            </Col>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    path: state.path,
    contexts: state.contexts,
    scopes: state.scopes,
    toasts: state.toasts.toastObjects,
    networkBlocks: state.networkBlocks,
    networkSubnets: state.networkSubnets,
    networkAddresses: state.networkAddresses
});

export default connect(mapStateToProps)(HierarchyView);