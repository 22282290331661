import {Form, FormControl, InputGroup} from "react-bootstrap";

const ScopeNameInput = ({values, errors, handleChange, valueKey = 'scopeName'}) => (
    <InputGroup hasValidation>
        <InputGroup.Text>Scope Name</InputGroup.Text>
        <FormControl name={valueKey}
                     onChange={handleChange}
                     value={values[valueKey]}
                     aria-label={valueKey}
                     isValid={values[valueKey] && !errors[valueKey]}
                     isInvalid={!!errors[valueKey]}/>
        <Form.Control.Feedback type="invalid">
            {errors[valueKey]}
        </Form.Control.Feedback>
    </InputGroup>
);

export default ScopeNameInput;